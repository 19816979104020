<template>
  <div>
    <v-progress-linear v-if="loading" :color="$getColor('vuetifyColor1')" indeterminate>
    </v-progress-linear>
    <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="pageSize"
    class="elevation-1"
  ></v-data-table>
  </div>
</template>
<script>
import axios from '@/services/http-common';
import authHeader from '@/services/auth-header';

export default {
  name: 'log-standard-table',
  props: {
    username: {
      type: String,
      default: null,
    },
    role: {
      type: String,
      default: null,
    },
    action: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'userModel.username',
        },
        { text: 'Action', value: 'action' },
        { text: 'Date', value: 'creationDate' },
      ],
      items: [],
      currentIndex: -1,
      searchTitle: '',
      page: 1,
      count: 0,
      pageSize: 10,
      pageSizes: [5, 10, 20],

      currentSort: 'creationDate',
      currentSortOrder: 'desc',
      loading: true,
    };
  },
  created() {
    this.getItems();
  },

  methods: {
    getRequestParams(page, pageSize) {
      const params = {};
      params.username = this.username;
      params.role = this.role;
      params.action = this.action;
      params.sort = this.currentSort;
      params.direction = this.currentSortOrder;

      if (page) {
        params.page = page - 1;
      }

      if (pageSize) {
        params.size = pageSize;
      }

      return params;
    },

    getItems() {
      this.loading = true;
      axios
        .get(`${process.env.VUE_APP_API_URL}log`, {
          headers: authHeader(),
          params: this.getRequestParams(this.page, this.pageSize),
        })
        .then((response) => {
          this.items = response.data.content;
          this.count = response.data.totalElements;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },

  },
};
</script>
