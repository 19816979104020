<template>
    <div class="dashboard-toolbar--selector">
      <div class="dashboard-toolbar--field-group">
        <div class="dashboard-toolbar--field-group__label work-center">
          <v-icon small>mdi-office-building-marker</v-icon>
          Centro de trabajo:
        </div>
        <div class="dashboard-toolbar--field-group__value">
          <div class="dashboard-toolbar--field-group__value__text">
            <!-- vuetify input selector -->
            <v-select v-model="selected" :items="workcenters" placeholder="Seleccionar Centro de Trabajo" outlined dense hide-details
              @change="updateWorkcenter()">
              <span class="selected-center">{{
                workcenter
              }}</span></v-select>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import workcenterService from '@/services/workcenter.service';

export default {
  name: 'workcenter-selector-nav',
  props: {
  },
  data: () => ({
    workcenters: null,
    sourceOptions: [],
    selected: null,
  }),
  created() {
    this.selected = this.workcenterId;
    this.getWorkcenters();
  },
  computed: {
    workcenterId() {
      if (this.$store.state.projectId) {
        // return as int
        return parseInt(this.$store.state.projectId, 10);
      }
      return null;
    },
    workcenter() {
      if (this.$store.state.project) {
        return this.$store.state.project;
      }
      return null;
    },
  },
  watch: {
    workcenterId() {
      this.selected = this.workcenterId;
    },
  },

  methods: {
    updateWorkcenter() {
      // find in workcenters array the selected workcenter
      const val = this.workcenters.find((ele) => ele.value === this.selected);
      this.$store.commit('setProject', val);
      if (this.$route.name === 'Home') {
        this.$router.go();
      } else {
        this.$router.push({ name: 'Home' });
      }
    },

    getWorkcenters() {
      workcenterService.getAllWorkcenters().then((response) => {
        this.sourceOptions = response.content.map((ele) => ({
          value: ele.id,
          text: ele.name,
          description: ele.description,
          typeId: ele.workcenterType,
          creationTime: new Date(ele.creationTime).toLocaleDateString(),
          icon: 'business_center',
          color: '#424242',
          availableClockEntries: ele.availableClockEntries,
          availableSchedule: ele.availableSchedule,
        }));
        this.workcenters = this.sourceOptions;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dashboard-toolbar--field-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &__label {
      font-size: calc(var(--base) * 2);
      line-height: calc(var(--base) * 2);
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: calc(var(--base) * 0.2);
      &.work-center {
        padding-bottom: calc(var(--base) * 1.1);
      }
    }

    &__value {
      font-weight: 500;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      align-items: flex-end;

      &.text-red {
        color: #f4796b;
      }
      &.text-green {
        color: #00bfa5;
      }
      &__text {
        display: block;
      }
      span.selected-center {
        font-size: calc(var(--base) * 2.5);
        line-height: auto;
        font-weight: 600;
        margin-left: calc(var(--base) * 0.3);
      }
      span.number {
        font-size: calc(var(--base) * 3.5);
        margin-right: calc(var(--base) * 0.5);
        line-height: calc(var(--base) * 2.2);
      }
      span.unit {
        font-size: calc(var(--base) * 2);
      }
    }
  }</style>
