<template>
  <div>
    <div class="loader-overlay" v-if="loading">
      <v-progress-linear :color="$getColor('vuetifyColor1')" class="linear-progress" indeterminate></v-progress-linear>
    </div>
    <v-data-table :headers="headers" :items="users" :options.sync="options" :server-items-length="count"
      class="elevation-1" calculate-widths :hide-default-footer="false" :footer-props="{
        showFirstLastPage: true,
        'items-per-page-text': 'Elementos por página',

      }" :color="$getColor('vuetifyColor1')" outlined flat elevation="0">
      <template v-slot:[`item.avatar`]="{ item }">
        <v-avatar color="primary" size="24">
          <span class="white--text avatar-text">{{ item.username.substring(1, 0).toUpperCase() }}</span>
        </v-avatar>
      </template>
      <template v-slot:[`item.role`]="{ item }">
        {{ $getRoleTypeValues(item.role).label }}
      </template>
      <template v-slot:[`item.workCenters`]="{ item }">
        <v-chip v-for="workCenter in item.workCenters" :key="workCenter.id" :color="$getColor('vuetifyColor1')"
          class="ma-1" small outlined>{{ workCenter.name }}</v-chip>
      </template>
      <template v-slot:[`item.enabled`]="{ item }">
        <v-icon v-if="!item.enabled" :color="$getColor('vuetifyColor1')" class="pl-2">lock</v-icon>
        <v-icon v-if="item.enabled" :color="$getColor('vuetifyColor2')" class="pl-2">check</v-icon>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab color="rgba(0,0,0,0.56)" text small v-bind="attrs" v-on="on"
              @click="goToUserActivityView(item.id)">
              <v-icon>mdi-account-clock-outline</v-icon>
            </v-btn>
          </template>
          <span>Panel de actividad de Usuario</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-show="item.deleteHide" fab color="rgba(0,0,0,0.56)" text small v-bind="attrs" v-on="on"
              @click="item.deleteHide = false"><v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          <span>Borrar Usuario</span>
        </v-tooltip>
        <v-btn fab v-show="!item.deleteHide" color="rgba(0,0,0,0.56)" text small
          @click="unregisterUser(item.username)"><v-icon color="teal accent-4">mdi-check-circle-outline</v-icon>
        </v-btn>
        <v-btn fab v-show="!item.deleteHide" color="rgba(0,0,0,0.56)" text small @click="item.deleteHide = true"><v-icon
            :color="$getColor('vuetifyColor1')">mdi-close-circle-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>

  </div>
</template>
<script>
import authHeader from '@/services/auth-header';

const axios = require('axios');

export default {
  name: 'users-table',
  props: {
    username: {
      type: String,
      default: null,
    },
    role: {
      type: Number,
      default: null,
    },
    workcenter: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      currentIndex: -1,
      searchTitle: '',
      page: 1,
      count: 0,
      pageSize: 5,
      pageSizes: [5, 10, 20],
      loading: false,
      users: [],
      currentSort: 'name',
      currentSortOrder: 'asc',

      options: {},
      headers: [

        { text: '', value: 'avatar' },
        { text: 'Usuario', value: 'username' },
        { text: 'Centro', value: 'workCenters' },
        { text: 'Rol', value: 'role' },
        { text: 'Estado', value: 'enabled' },
        {
          text: 'Acciones',
          sortable: false,
          value: 'actions',
        },

      ],
    };
  },
  watch: {
    role() {
      this.getUserItems();
    },
    username() {
      this.getUserItems();
    },
    workcenter() {
      this.getUserItems();
    },
    options: {
      handler() {
        this.getUserItems();
      },
      deep: true,
    },
  },
  methods: {
    getProjectsCount(val) {
      if (val) {
        return val.length;
      }
      return 0;
    },

    goToUserActivityView(id) {
      return this.$router.push({ name: 'User Panel', params: { id } });
    },
    emitCount() {
      this.$emit('emitCount', this.count);
    },

    getRequestParams() {
      const params = {};
      params.username = this.username;
      params.role = this.role;
      params.workCenterId = this.workcenter;
      if (this.options.sortBy) {
        // eslint-disable-next-line prefer-destructuring
        params.sort = this.options.sortBy[0];
      }
      if (this.options.sortDesc) {
        params.direction = 'desc';
      } else {
        params.direction = 'asc';
      }
      params.page = this.options.page - 1;
      params.size = this.options.itemsPerPage;
      return params;
    },

    getUserItems() {
      this.loading = true;
      axios
        .get(`${process.env.VUE_APP_API_URL}users/`, {
          headers: authHeader(),
          params: this.getRequestParams(),
        })
        .then((response) => {
          this.users = response.data.content.map((ele) => ({
            id: ele.id,
            username: ele.username,
            role: ele.role,
            projects: ele.projects,
            deleteHide: true,
            enabled: ele.enabled,
            workCenters: ele.workCenters,
          }));

          this.count = response.data.totalElements;
          this.emitCount();
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    unregisterUser(userselected) {
      this.loading = true;
      axios
        .post(
          `${process.env.VUE_APP_API_URL}unregister/`,
          {
            username: userselected,
          },
          { headers: authHeader() },
        )
        .then(() => {
          this.loading = false;
          this.getUserItems();
        });
    },

  },
};
</script>
<style lang="scss" scoped>
.table_box {
  min-height: 150px;
  overflow: hidden;
  border-left: solid 1px #e5e5e5 !important;
}
</style>
<style lang="scss" >

.loader-overlay {
  position: relative;
}
</style>
