/* eslint-disable class-methods-use-this */
import axios from 'axios';
import authHeader from '@/services/auth-header';

const API_URL = `${process.env.VUE_APP_API_URL}`;

class LoggedUserService {
  getWorker() {
    return axios
      .get(`${API_URL}logged/worker`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  getWorkCenter() {
    return axios
      .get(`${API_URL}logged/workcenter`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  getIncidences() {
    return axios
      .get(`${API_URL}logged/incidences`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  validateUserResetPassword(password, cod) {
    return axios
      .post(
        `${process.env.VUE_APP_API_URL}recover-password-confirm`,
        {
          password,
          validationCode: cod,
        },
      )
      .then((response) => response)
      .catch((error) => error);
  }

  validateUser(password, cod, id) {
    return axios
      .post(
        `${process.env.VUE_APP_API_URL}users`,
        {
          password,
          validationCode: cod,
          workerId: id,
        },
      )
      .then((response) => response)
      .catch((error) => error);
  }
}

export default new LoggedUserService();
