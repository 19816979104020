<template>
  <div>
    <v-btn depressed class="mb-3" :dark="$getPrimaryButton('dark')"
          :rounded="$getPrimaryButton('rounded')" :outlined="$getPrimaryButton('outlined')"
          :color="$getPrimaryButton('color')" @click="goToUsers()">
          <v-icon left>mdi-arrow-left</v-icon>Volver
    </v-btn>
    <div class="grid-container">
      <div class="left-side">
        <v-card :elevation="$getCardOptions('elevation')" :loading="loading"
        class="card dashboard_box">
          <div class="card-thumbnail">
            <v-avatar :color="$getColor('vuetifyColor1')" size="62">
              <span class="white--text text-h5">{{userChar}}</span>
            </v-avatar>
          </div>
          <v-card-text class="card-label">
            <div>Usuario:</div>
          </v-card-text>
          <v-card-title class="card-title">{{ user.username }}</v-card-title>
          <v-card-text class="card-label">
            <div>Rol:</div>
          </v-card-text>
          <div class="card-role-wrapper">
            <div class="role-title" v-if="user.role">{{ $getRoleTypeValues(user.role).label }}</div>
            <div class="button-edit">
              <v-btn :color="$getColor('vuetifyColor1')" class="mr-2" text small
                v-on:click="userEditIsHidden = false" v-if="userEditIsHidden">
                <v-icon small class="mr-2">mdi-pencil</v-icon>
                Editar
              </v-btn>
            </div>
            <div class="edit-user-dialog" v-if="!userEditIsHidden">
              <!--
                <v-select :items="RoleOptions" v-model="newRole" outlined
                :color="$getColor('vuetifyColor1')" label="ROLE_SUPER_ADMIN" dense
                class="rol-select"></v-select>
              -->
                <property-type-select propertyValue="roles" label="Rol" icon="admin_panel_settings"
                  @updateParent="updateRole">
              </property-type-select>
              <v-btn :color="$getColor('vuetifyColor1')" class="mr-2" text small
                v-on:click="userEditIsHidden = !userEditIsHidden">
                Cancelar
              </v-btn>
              <v-btn :color="$getColor('vuetifyColor1')" text small @click="patchUserRole()">
                Guardar
              </v-btn>
            </div>
          </div>
          <!-- -->
          <v-card-text class="card-label mt-2">
            <div>Estado:</div>
          </v-card-text>
          <div class="card-role-wrapper">
            <!-- if user.enabled -> enabled; else -> block -->
            <div v-if="user.enabled" class="role-title">
              Habilitado
            </div>
            <div v-if="!user.enabled" class="role-title">
              Bloqueado
            </div>
            <div class="button-edit">
              <v-btn :color="$getColor('vuetifyColor1')" class="mr-2" text small
                v-on:click="roleEditIsHidden = false" v-if="roleEditIsHidden">
                <v-icon small class="mr-2">mdi-pencil</v-icon>
                Editar
              </v-btn>
            </div>
            <div class="edit-user-dialog" v-if="!roleEditIsHidden">
              <v-select :items="StatusOptions" v-model="newStatus" outlined
                :color="$getColor('vuetifyColor1')" label="Status" dense
                class="rol-select"></v-select>
              <v-btn :color="$getColor('vuetifyColor1')" class="mr-2" text small
                v-on:click="roleEditIsHidden = !roleEditIsHidden">
                Cancelar
              </v-btn>
              <v-btn :color="$getColor('vuetifyColor1')" text small @click="patchUserStatus()">
                Guardar
              </v-btn>
            </div>
          </div>
          <!-- -->
          <v-divider class="mx-4 card-divider"></v-divider>
          <v-card-actions class="mb-5 mx-auto">
            <!-- Add card actions -->
            <v-btn class="m-3" :color="$getColor('vuetifyColor1')"
            dark large @click="unregisterUser()" outlined>
            <v-icon left>delete</v-icon>
              Borrar usuario
            </v-btn>
          </v-card-actions>
          <!--
            <v-divider class="mx-4 card-divider"></v-divider>
          <v-card-title class="card-title">User Projects</v-card-title>
          <div class="projects-table">
            <div class="dashboard_box">
              <ProjectsTable ref="userProjectTable" />
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-card-actions class="card-actions">
            <v-btn :color="$getColor('vuetifyColor1')" text
              v-on:click="projectEditIsHidden = !projectEditIsHidden" v-if="projectEditIsHidden">
              <v-icon class="mr-2">mdi-plus</v-icon>
              Add project
            </v-btn>
            <div class="add-project-dialog" v-if="!projectEditIsHidden">
              <v-select :items="ProjectOptions" v-model="projectSelected" outlined
                :color="$getColor('vuetifyColor1')" label="Project" dense class="rol-select">
              </v-select>
              <v-btn :color="$getColor('vuetifyColor1')" class="mr-2" text small
                v-on:click="projectEditIsHidden = true">
                Cancel
              </v-btn>
              <v-btn :color="$getColor('vuetifyColor1')" text small
                v-on:click="projectEditIsHidden = true" @click="addProjectToUser()">
                Save
              </v-btn>
            </div>
          </v-card-actions>
        -->
        </v-card>
      </div>
      <div class="right-side">
        <v-tabs v-model="tabActivityLog" background-color="transparent"
          :color="$getColor('vuetifyColor1')" class="dashboard_box panel-tab" grow>
          <v-tab v-for="item in tabsActivityLog" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
        <div class="chart-box dashboard_box" v-show="tabActivityLog === 0">
          <ActivityChart :color="$getColor('vuetifyColor1')" ref="userActivityChart" />
        </div>
        <div class="log-box" v-show="tabActivityLog === 1">
          <log-standard-table  :username="user.username"> </log-standard-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authHeader from '@/services/auth-header';
import authService from '@/services/auth.service';

import PropertyTypeSelect from '@/components/Forms/PropertiesSelect.vue';
import ActivityChart from '../../components/Charts/ActivityChart.vue';
import LogStandardTable from '../../components/Tables/Activity/LogActivityTable.vue';
// import ProjectsTable from '../../components/Tables/ProjectsTable.vue';

const axios = require('axios');

export default {
  props: {
    cardUserImage: {
      type: String,
      // eslint-disable-next-line global-require
      default: null,
    },
  },
  components: {
    ActivityChart,
    LogStandardTable,
    PropertyTypeSelect,
    // ProjectsTable,
  },
  data() {
    return {
      userId: null,
      authService,
      user: [],
      addProject: false,
      tabsActivityLog: ['Actividad', 'Log'],
      tabActivityLog: null,
      RoleOptions: [
        { value: 'ROLE_USER', text: 'ROLE_USER' },
        { value: 'ROLE_SUPER_ADMIN', text: 'ROLE_SUPER_ADMIN' },
      ],
      StatusOptions: [
        { value: true, text: 'Habilitado' },
        { value: false, text: 'Bloqueado' },
      ],
      ProjectOptions: [],
      projectSelected: null,
      userActivityData: {
        totalActivity: [],
      },
      userEditIsHidden: true,
      roleEditIsHidden: true,
      projectEditIsHidden: true,
      loading: false,
      newRole: null,
      newStatus: null,
    };
  },
  computed: {
    userChar() {
      if (this.authService.getDecodedJwtToken(this.$store.state.auth.user)) {
        const name = this.authService.getDecodedJwtToken(this.$store.state.auth.user).sub;
        return name.substring(1, 0).toUpperCase();
      }
      return 'A';
    },
  },

  methods: {
    updateRole(val) {
      this.newRole = val;
    },
    updateUserLocalData() {
      // this.breadcrumbs[2].text = this.user.username;
      this.newRole = this.user.role;
      // this.$refs.userProjectTable.updateUsername(this.user.username);
    },
    patchUserRole() {
      this.isHidden = true;
      this.user.role = this.newRole;
      this.pathUser();
      this.userEditIsHidden = true;
    },

    patchUserStatus() {
      this.roleEditIsHidden = true;
      this.user.enabled = this.newStatus;
      this.pathStatus();
    },
    addProjectToUser() {
      this.projectEditIsHidden = true;
      this.addProjects();
      this.updateUserLocalData();
    },
    unregisterUser() {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}unregister/`,
          {
            username: this.user.username,
          },
          { headers: authHeader() },
        )
        .then(() => {
          this.$router.push({ name: 'Users' });
        });
    },
    pathStatus() {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}update-user-enabled/${this.$route.params.id}`,
          {
            enabled: this.user.enabled,
          },
          { headers: authHeader() },
        )
        .then(() => {
          this.getUser();
        })
        .catch(() => {
          this.$router.push('/error');
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
          this.getUser();
        });
    },

    getUser() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}userById/${this.$route.params.id}`,
          {
            headers: authHeader(),
          },
        )
        .then((response) => {
          this.user = response.data;
          this.updateUserLocalData();
          // this.getProjects();
          if (!response.data) {
            this.$router.push('/error-no-encontrado');
          }
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getProjects() {
      axios
        .get(`${process.env.VUE_APP_API_URL}analizados/user-available-projects/${this.user.username}`, {
          headers: authHeader(),
        })
        .then((response) => {
          this.ProjectOptions = response.data.map((ele) => ({
            value: ele.projectId,
            text: ele.nombre,
          }));
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addProjects() {
      if (this.projectSelected) {
        axios
          .post(
            `${process.env.VUE_APP_API_URL}projectPermissions`,
            {
              username: this.user.username,
              projectId: this.projectSelected,
            },
            { headers: authHeader() },
          )
          .then((response) => {
            console.log(response);
            this.getProjects();
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    pathUser() {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}update-user-role/${this.$route.params.id}`,
          {
            username: this.user.username,
            role: this.user.role,
          },
          { headers: authHeader() },
        )
        .then(() => {
          this.getUser();
        })
        .catch(() => {
          this.$router.push('/error');
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    loadActivityData() {
      const params = {};
      if (this.$route.params.id) {
        params.userId = this.$route.params.id;
      }
      axios
        .get(`${process.env.VUE_APP_API_URL}stats/userActivity/`, {
          params,
          headers: authHeader(),
        })
        .then((response) => {
          this.userActivityData.totalActivity = [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ];

          response.data.forEach((element) => {
            const claveMonth = element[0];
            const valor = element[1];
            if (claveMonth) {
              this.userActivityData.totalActivity[claveMonth - 1] = valor;
            }
          });

          this.$refs.userActivityChart.updateActivityData(
            this.userActivityData,
          );
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goToUsers() {
      this.$router.push({ name: 'Users' });
    },
  },
  created() {
    this.getUser();
    this.loadActivityData();
  },
};
</script>
<style lang="scss">

.button-edit {
  margin-right: 1em;
  margin-left: 1em;
  display: flex;
  justify-content: flex-end;
}

.grid-container {
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(12, 1fr);

  .left-side {
    grid-column: 1 / span 5;
  }

  .right-side {
    grid-column: 6 / span 7;
  }

  .card {
    padding: 0;
    padding-top: 2em;
  }
}

.dashboard_box {
  border: solid 1px #e5e5e5;
}

.card-thumbnail {
  padding: 1em;
  width: 100%;
  // min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;

  .card-avatar {
    margin: 0;
  }
}

.card-label.v-card__text {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0.5em;
}

.v-card__title.card-title {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 1em;
}

// .projects-table {
//   padding-left: 1em;
//   padding-right: 1em;
// }

.card-divider {
  margin-bottom: 2em;
}

.card-actions.v-card__actions {
  padding: 1em;
  margin-top: 0;
}

.dashboard_box.chart-box {
  margin-bottom: 1em;
}

.panel-tab {
  margin-bottom: 1em;
}

.chart-box {
  padding: 1em;
}

.card-role-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;

  .role-title {
    font-size: 1.25rem;
  }
}

.add-project-dialog {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.edit-user-dialog {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.rol-select {
  margin-right: 1em;
}

.edit-user-dialog {
  width: 100%;
  margin-top: 1em;
}
</style>
