<template>
  <div class="view--worker-panel">
    <div class="back-button">
      <v-btn depressed color="primary" dark @click="goBack()">
        <v-icon>mdi-arrow-left</v-icon>
        Volver
      </v-btn>
    </div>
    <div class="grid-container">
      <div class="left-side">
        <mdtec-panel class="worker-panel">
          <template v-slot:title> Panel de Trabajador/a </template>
          <template v-slot:headerActions>
            <v-tooltip bottom v-if="isAdmin && userModel">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  icon
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  v-on:click="goToUserPanel()"
                >
                  <v-icon>mdi-account-cog</v-icon>
                </v-btn>
              </template>
              <span>Ir al panel de usuario</span>
            </v-tooltip>
          </template>
          <template v-slot:content>
            <!-- vuetify avatar -->
            <div class="worker-avatar">
              <v-avatar :color="$getColor('vuetifyColor1')" size="64">
                <span class="white--text text-h5">{{ userChar }}</span>
              </v-avatar>
            </div>
            <div class="worker-info">
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    label="Nombre"
                    :value="user.name"
                    readonly
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                    label="Apellidos"
                    :value="user.lastName1"
                    readonly
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                    label="Email"
                    :value="user.email"
                    readonly
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="DNI"
                    :value="user.idDocument"
                    readonly
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Fecha de creación"
                    :value="new Date(user.creationTime).toLocaleString()"
                    readonly
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:footerActions v-if="this.$store.getters['auth/hasRole'](['ROLE_SUPER_ADMIN','ROLE_ADMIN' ])">
            <v-btn large v-if="editAccess" text color="rgba(0,0,0,0.6)" @click="editAccess = false">
              <v-icon> mdi-close</v-icon>Cancelar
            </v-btn>
            <v-btn
              v-if="!editAccess"
              depressed
              color="primary"
              dark
              large
              v-on:click="editAccess = true"
            >
              <v-icon small class="mr-2">edit</v-icon>
              Editar acceso
            </v-btn>
            <v-btn
              v-if="editAccess"
              depressed
              color="primary"
              dark
              large
              v-on:click="removeWorker()"
            >
              <v-icon small class="mr-2">delete</v-icon>
              Borrar Trabajador/a
            </v-btn>
            <v-btn
             v-if="editAccess && userModel && userModel.enabled"
              depressed
              color="primary"
              dark
              large
              v-on:click="disableUser()"
            >
              <v-icon small class="mr-2">block</v-icon>
              Bloquear usuario
            </v-btn>
          </template>
        </mdtec-panel>
      </div>
      <div class="right-side">
        <v-tabs
          v-model="tabActivityLog"
          background-color="transparent"
          :color="$getColor('vuetifyColor1')"
          class="dashboard_box panel-tab"
          grow
        >
          <v-tab v-for="item in tabsActivityLog" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
        <div class="mdtec-card calendar" v-show="tabActivityLog === 0">
          <div class="mdtec-card--header">
            <div class="mdtec-card--header__title">
              Cuadrante de {{ user.name }} {{ user.lastName1 }}
            </div>
            <div class="mdtec-card--header__actions">
              <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  color="primary"
                  dark
                  icon
                  @click="setIsDetailView(!isDetailView)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ !isDetailView ? 'view_list' : 'event' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ !isDetailView ? 'Ver cuadrante en formato lista' : 'Ver cuadrante en formato calendario' }}</span>
            </v-tooltip>
              <v-btn depressed color="primary" dark v-on:click="goToQuadrant()">
                <v-icon small class="mr-2">edit_calendar</v-icon>
                Editar Cuadrante
              </v-btn>
            </div>
          </div>
          <WorkerCalendar v-if="this.user" :workerId="this.$route.params.id" ref="workerCalendar" />
        </div>
        <div class="mdtec-card full-height" v-show="tabActivityLog === 1">
          <div class="mdtec-card--header">
            <div class="mdtec-card--header__title">
              Incidencias de {{ user.name }} {{ user.lastName1 }}
            </div>
            <div class="mdtec-card--header__actions">
              <v-btn
                depressed
                color="primary"
                dark
                v-on:click="goToIncidences()"
              >
                <v-icon small class="mr-2">mdi-plus</v-icon>
                Añadir Incidencia
              </v-btn>
            </div>
          </div>
          <div class="mdtec-card--content">
            <Incidences
              :isWorker="true"
              :workerId="this.$route.params.id"
              @updateTotal="updateTotalIncidences"
            />
          </div>
        </div>
        <mdtec-panel v-show="tabActivityLog === 2" class="full-height" v-if="this.$store.getters['auth/hasRole'](['ROLE_SUPER_ADMIN','ROLE_ADMIN' ])">
          <template v-slot:title>
            Códigos de {{ user.name }} {{ user.lastName1 }}</template
          >
          <template v-slot:headerActions v-if="isAdmin">
            <v-btn
              depressed
              color="primary"
              dark
              v-on:click="codeEditIsHidden = !codeEditIsHidden"
              :disabled="!codeEditIsHidden"
            >
              <v-icon small class="mr-2">mdi-file-document-plus</v-icon>
              Añadir Código SAP
            </v-btn>
          </template>
          <template v-slot:content >
            <div class="add-project-dialog" v-if="isAdmin && !codeEditIsHidden">
              <v-select
                :items="CodesOptions"
                v-model="codesSelected"
                multiple
                outlined
                :color="$getColor('vuetifyColor1')"
                label="Añadir códigos..."
                dense
                class="rol-select"
                hide-details
              >
              </v-select>
              <!--
                <v-select
                :items="CodesOptions"
                v-model="codeSelected"
                outlined
                :color="$getColor('vuetifyColor1')"
                label="Añadir código..."
                dense
                class="rol-select"
                hide-details
              >
              </v-select>
              -->

              <v-btn
                :color="$getColor('vuetifyColor1')"
                class="mr-2"
                text
                small
                v-on:click="codeEditIsHidden = true"
              >
                Cancelar
              </v-btn>

              <!--
                <v-btn
                :color="$getColor('vuetifyColor1')"
                text
                small
                v-on:click="codeEditIsHidden = true"
                @click="addCodeToUser()"
              >
                Guardar
              </v-btn>
               -->
              <v-btn
                :color="$getColor('vuetifyColor1')"
                text
                small
                v-on:click="codeEditIsHidden = true"
                @click="addCodesToUser()"
              >
                Guardar
              </v-btn>
            </div>
            <div class="projects-table">
              <!-- loader -->
              <div class="panel-loader" v-if="loadingCodes">
                <v-progress-circular indeterminate :color="$getColor('vuetifyColor1')"></v-progress-circular>
              </div>
              <CodesTable v-show="!loadingCodes" ref="codesTable" />
            </div>
          </template>
        </mdtec-panel>
      </div>
    </div>
  </div>
</template>

<script>
import authService from '@/services/auth.service';

import workcenterService from '@/services/workcenter.service';
import userService from '@/services/user.service';
import CodesTable from '@/views/_FATDA/components/UserSapCodes.vue';
import WorkerCalendar from '@/views/_FATDA/components/WorkerCalendar.vue';
import Incidences from '@/views/_FATDA/components/Incidences.vue';

import MdtecPanel from '@/components/Cards/MdtecPanel.vue';

export default {
  props: {
  },
  components: {
    CodesTable,
    WorkerCalendar,
    MdtecPanel,
    Incidences,
  },
  data() {
    return {
      userId: null,
      authService,
      user: [],
      userModel: null,
      addProject: false,

      tabActivityLog: null,
      tabWorker: null,
      StatusOptions: [
        { value: true, text: 'Habilitado' },
        { value: false, text: 'Bloqueado' },
      ],
      CodesOptions: [],
      codeSelected: null,
      codesSelected: [],
      userActivityData: {
        totalActivity: [],
      },
      userEditIsHidden: true,
      roleEditIsHidden: true,
      codeEditIsHidden: true,
      loading: false,
      loadingCodes: false,
      newRole: null,
      newStatus: null,
      totalIncidences: 0,
      isAdmin: false,
      editAccess: false,

      isDetailView: false,
    };
  },
  computed: {
    userChar() {
      if (this.user && this.user.name && this.user.lastName1) {
        const name = this.user.name.substring(1, 0).toUpperCase();
        const lastName1 = this.user.lastName1.substring(1, 0).toUpperCase();
        return `${name}${lastName1}`;
      }
      return 'XX';
    },
    tabsActivityLog() {
      // if isAdmin is true, show all tabs
      if (this.isAdmin) {
        return ['Cuadrante', 'Incidencias', 'Códigos SAP'];
      }
      return ['Cuadrante', 'Incidencias'];
    },

  },

  methods: {

    patchUserStatus(value) {
      this.user.enabled = value;
    },
    addCodeToUser() {
      this.codeEditIsHidden = true;
      this.addCode();
    },
    addCodesToUser() {
      this.addCodes();
    },

    goToQuadrant() {
      this.$router.push({ name: 'ConfigureTimeAdmin', params: { id: this.$route.params.id } });
    },
    goToUserPanel() {
      this.$router.push({ name: 'User Panel', params: { id: this.userModel.id } });
    },
    goBack() {
      this.$router.go(-1);
    },
    removeWorker() {
      workcenterService.removeWorker(this.user.email, this.$store.state.projectId).then(() => {
        this.$router.push({ name: 'Workers' });
      });
    },
    disableUser() {
      userService.disableUser(this.userModel.id).then(() => {
        this.getWorker();
      });
    },

    getWorker() {
      workcenterService.getWorker(this.$route.params.id).then((response) => {
        this.user = response;
        this.getUser(this.user.email);
        this.getUserCodes();
        if (this.isAdmin) {
          this.$refs.codesTable.updateCodes(this.user.n2Codes, this.user.id);
        }
      });
    },
    getUserCodes() {
      if (this.$store.getters['auth/hasRole'](['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])) {
        workcenterService.getN2Codes(this.$store.state.projectId).then((response) => {
          const allCodes = response.map((ele) => ({
            value: ele.id,
            text: `${ele.name} - ${ele.code}`,
          }));
          // exclude codes already assigned to user
          this.CodesOptions = allCodes.filter((code) => !this.user.n2Codes.some((userCode) => userCode.id === code.value));
        });
      }
    },
    addCode() {
      if (this.codeSelected) {
        workcenterService.addN2CodeToWorker(this.$route.params.id, this.codeSelected).then(() => {
          this.getWorker();
        });
      }
    },
    addCodes() {
      if (this.codesSelected) {
        this.loadingCodes = true;
        // start a loop to add all codes
        // Inside the loop, create a promise to add the code and wait for it to resolve before adding the next one
        let promise = Promise.resolve();
        this.codesSelected.forEach((code) => {
          promise = promise.then(() => workcenterService.addN2CodeToWorker(this.$route.params.id, code));
        });
        promise.then(() => {
          this.getWorker();
          this.codeEditIsHidden = true;
          this.loadingCodes = false;
        });
      }
    },
    goToWorkers() {
      this.$router.push({ name: 'Workers' });
    },
    updateTotalIncidences(total) {
      this.totalIncidences = total;
    },
    getUser(email) {
      userService.userByUsername(email).then((response) => {
        this.userModel = response;
      });
    },
    setIsDetailView(val) {
      this.isDetailView = val;
      this.$refs.workerCalendar.updateIsDetailView(val);
    },
  },
  created() {
    this.isAdmin = this.$store.getters['auth/hasRole'](['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']);
    this.getWorker();
  },
};
</script>
<style lang="scss" scoped>
.view--worker-panel {
  height: 100%;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .grid-container {
    display: grid;
    gap: calc(var(--base) * 2);
    grid-template-columns: repeat(12, 1fr);
    height: 100%;

    .left-side {
      grid-column: 1 / span 5;
    }
    @media (max-width: 768px) { /* Este valor puede ajustarse según el tamaño de pantalla que consideres "móvil" */
  .left-side {
    grid-column: 1 / span 12;
  }
  }
    .right-side {
      grid-column: 6 / span 7;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    @media (max-width: 768px) { /* Este valor puede ajustarse según el tamaño de pantalla que consideres "móvil" */
  .right-side {
    grid-column: 1 / span 12;
  }
  }

    .card {
      padding: 0;
      padding-top: 2em;
    }
    .full-height {
      height: 100%;
    }
  }
}
.button-edit {
  margin-right: 1em;
  margin-left: 1em;
  display: flex;
  justify-content: flex-end;
}
.worker-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  padding: calc(var(--base) * 3) 0;
}
.worker-info {
  padding-top: calc(var(--base) * 2);
}

.dashboard_box {
  border: solid 1px #e5e5e5;
}

.card-thumbnail {
  padding: 1em;
  width: 100%;
  // min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;

  .card-avatar {
    margin: 0;
  }
}

.card-label.v-card__text {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0.5em;
}

.v-card__title.card-title {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 1em;
}

// .projects-table {
//   padding-left: 1em;
//   padding-right: 1em;
// }

.card-divider {
  margin-bottom: 2em;
}

.card-actions.v-card__actions {
  padding: 1em;
  margin-top: 0;
}

.dashboard_box.chart-box {
  margin-bottom: 1em;
}

.panel-tab {
  margin-bottom: 1em;
}

.chart-box {
  padding: 1em;
}

.card-role-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;

  .role-title {
    font-size: 1.25rem;
  }
}

.add-project-dialog {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.edit-user-dialog {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 1em;
}

.rol-select {
  margin-right: 1em;
}

</style>
<style lang="scss">
.view--worker-panel {
  & .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgba(var(--bodyColor), 0.1) !important;
    color: rgba(var(--bodyColor), 0.6) !important;
  }
  & .theme--dark.v-btn.v-btn--disabled,
  .theme--dark.v-btn.v-btn--disabled .v-btn__loading,
  .theme--dark.v-btn.v-btn--disabled .v-icon {
    color: rgba(var(--bodyColor), 0.6) !important;
  }
  & .mdtec-card.worker-panel {
    height: 100%;
  }
  & .mdtec-card.calendar {
    height: 100%;
    & .mdtec-card--content {
      height: 100%;
      & .admin--worker-calendar {
        height: 100%;
        & .calendar {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
      }
    }
    & .theme--light.v-calendar-weekly .v-calendar-weekly__day {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    & .v-event.v-event-start.v-event-end.white--text {
      width: 98% !important;
      & .event-chip {
        color: #161616 !important;
        padding: 0 calc(var(--base) * 0.5);
        font-weight: 400;
        justify-content: flex-start;
      }
    }
  }
}
.panel-loader {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
</style>
