<template>
  <div>
    <v-dialog v-model="dialog" class="dialog-wrapper" width="450px">
      <template v-slot:activator="{ on, attrs }">
        <div class="add_user-btn">
          <v-btn :dark="$getPrimaryButton('dark')" :rounded="$getPrimaryButton('rounded')"
            :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')" block v-bind="attrs" v-on="on">
            <v-icon left v-if="icon"> {{icon}} </v-icon>
            {{title}}
          </v-btn>
        </div>
      </template>
      <v-card class="dialog-box">
        <v-card-title class="dialog-title" :color="$getColor('vuetifyColor1')">
          <span class="text-h5">{{title}}</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            outlined
            :color="$getColor('vuetifyColor1')"
            label="Email"
            placeholder="ejemplo@hola.es"
            v-model="user.email"
            required
            class="mb-2"
          ></v-text-field>
        <property-type-select propertyValue="roles" label="Rol"
                  @updateParent="updateRole" ref="roleSelect" >
        </property-type-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :color="$getColor('vuetifyColor1')" text @click="dialog = false">
            Cancelar
          </v-btn>
          <v-btn :color="$getColor('vuetifyColor1')" text @click="newUser()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import authHeader from '@/services/auth-header';
import PropertyTypeSelect from '@/components/Forms/PropertiesSelect.vue';

const axios = require('axios');

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      user: {
        email: null,
        role: null,
      },
      dialog: false,
    };
  },
  components: {
    PropertyTypeSelect,
  },
  methods: {
    updateRole(value) {
      this.user.role = value;
    },

    newUser() {
      if (this.user.email && this.user.role != null) {
        axios
          .post(
            `${process.env.VUE_APP_API_URL}create-user/`,
            {
              username: this.user.email,
              role: this.user.role,
            },
            { headers: authHeader() },
          )
          .then(() => {
            this.dialog = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add_user-btn {
  max-width: 15em;
  margin-top: 0;
  margin-bottom: 1em;
}
.dialog-title{
    margin-bottom: 1em;
    span{
        color: var(--bodyColor);
    }
}
.dialog-wrapper{
    max-width: 250px;
    .v-dialog--active{
        max-width: 250px !important;
    }
}
</style>
