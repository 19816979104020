<template>
    <div class="container-max-width p-3 pt-5">
        <div class="row-month-selection" v-if="pickerView">
            <v-date-picker v-model="selectedMonthPicker" :landscape="false" type="month" locale="es" scrollable
            :color="$getPrimaryButton('color')" width="300px"></v-date-picker></div>
        <div class="row-month-selection" v-if="!pickerView">
            <v-btn @click="substractMonthSelected()" icon :dark="$getPrimaryButton('dark')"
                depressed :rounded="$getPrimaryButton('rounded')" :outlined="true"
                :color="$getPrimaryButton('color')" class="mr-3">
                <v-icon>mdi-arrow-left-bold</v-icon>
            </v-btn>
            <SectionHeader first="Exportar"
                :second="'fichajes del mes de ' + getMonthName(this.selectedMonth + 1) + ', '" />
            {{ this.selectedMonth }}
            {{ this.selectedDate }}
            <v-btn @click="addMonthSelected()" icon :dark="$getPrimaryButton('dark')" depressed
                :rounded="$getPrimaryButton('rounded')" :outlined="true"
                :color="$getPrimaryButton('color')" class="ml-3">
                <v-icon>mdi-arrow-right-bold</v-icon>
            </v-btn>
        </div>
        <v-spacer></v-spacer>
        <!-- Add v-date picker with v-menu for dateEnd-->
        <!-- Button to generate N2 -->
        <v-btn large class="mt-5 white--text" :rounded="$getPrimaryButton('rounded')"
            :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')"
            @click="exportFichajes" :disabled="!selectedMonthPicker" :loading="loading">
            Exportar fichajes
        </v-btn>
    </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import scheduleService from '@/services/schedule.service';
import SectionHeader from '@/components/Headers/SectionHeader.vue';
import workcenterService from '@/services/workcenter.service';

export default {
  name: 'export-clock-entries',
  data() {
    return {
      loading: false,

      workcenterId: null,
      workcenterName: null,

      selectedDate: new Date(),
      selectedMonth: new Date().getMonth(),
      selectedMonthPicker: null,

      pickerView: true,
      workers: null,
      clockEntries: null,
    };
  },
  components: {
    SectionHeader,
  },
  mounted() {
    if (this.$store.state.projectId === null) {
      this.$router.push({ name: 'SelectWorkCenter' });
    } else {
      this.workcenterId = this.$store.state.projectId;
      this.workcenterName = this.$store.state.project;
      // this.getWorkers();
    }
  },
  methods: {
    async getWorkers() {
      this.loading = true;
      await workcenterService.getWorkers({ size: 999, workCenter: this.$store.state.projectId, direction: 'asc' }).then((response) => {
        this.workers = response.content.map((user) => ({
          id: user.id,
          name: user.name,
          lastName1: user.lastName1,
          email: user.email,
          fullName: `${user.name} ${user.lastName1}`,
        }));
        console.log(this.workers);
        this.loading = false;
      });
    },
    exportFichajes() {
      this.loading = true;
      const dateRange = scheduleService.getDateTimeRange(this.selectedMonthPicker, 'YYYY-MM');
      // const dateRangeMM = scheduleService.getDateTimeRange(this.selectedMonth, 'MM');

      scheduleService.getWorkersClockEntriesInRange(this.workcenterId, dateRange.dateFrom, dateRange.dateTo).then((response) => {
        this.clockEntries = response;
        this.exportClockEntries();
      });
    },
    exportClockEntries() {
      const eventsCl = [];
      // items foreach
      this.clockEntries.forEach((item) => {
        eventsCl.push({
          id: item.id,
          date: item.clockInTime,
          unixClockInTime: item.unixClockInTime,
          sourceType: item.type,
          manualEntry: item.manualEntry,
          adminEntry: item.adminEntry,
          time: scheduleService.unixTimeToDateTime(item.unixClockInTime),
          timeLocale: scheduleService.unixTimeToLocaleDateTime(item.unixClockInTime),
          type: item.type ? this.$getclockEntryTypeValues(item.type).label : 'N/A',
          workerId: item.worker.id,
          workerName: item.worker.name,
          workerLastName: item.worker.lastName1,
          workerEmail: item.worker.email,
          worker: item.worker,
        });
      });
      this.eventsClock = eventsCl;
      scheduleService.downloadClockEntriesForAllWorkers(eventsCl);
      this.loading = false;
    },
    getMonthName(numeroMes) {
      // Verificar si el número de mes está dentro del rango válido (1 a 12)
      if (numeroMes >= 1 && numeroMes <= 12) {
      // Crear una instancia de Date con el año actual y el número de mes proporcionado
        const fecha = new Date(new Date().getFullYear(), numeroMes - 1, 1); // Restar 1 al número de mes (0-indexed)
        // Obtener el nombre del mes en español utilizando toLocaleString()
        const nombreMes = fecha.toLocaleString('es-ES', { month: 'long' });
        return nombreMes.charAt(0).toUpperCase() + nombreMes.slice(1); // Capitalizar la primera letra del nombre del mes
      }
      return 'Mes inválido'; // Manejar casos fuera del rango
    },
    refreshClockEntries() {
      // minDate = first day of current month
      const minDateClockEntries = new Date(new Date().getFullYear(), this.selectedMonth, 2);
      // maxDate = last day of the month
      const maxDateClockEntries = new Date(new Date().getFullYear(), this.selectedMonth + 1, 1);
      const dateFromClockEntries = new Date(minDateClockEntries).toISOString().split('T')[0];
      // const dateToClockEntries = new Date(maxDateClockEntries).toISOString().split('T')[0];

      // new parse date
      // Restar un milisegundo para obtener el último momento del mes actual
      maxDateClockEntries.setMilliseconds(maxDateClockEntries.getMilliseconds() - 1);
      // Crear la fecha final en el formato ISO y obtener solo la parte de la fecha
      const dateToClockEntries = maxDateClockEntries.toISOString();
      // end new parse date

      scheduleService.getClockEntriesInRange(this.user.worker, dateFromClockEntries, dateToClockEntries).then((response) => {
        this.clockEntriesCurrent = response;
        this.updateRangeClockEntries();
      });
    },
    addMonthSelected() {
      this.selectedMonth += 1;
    },
    substractMonthSelected() {
      this.selectedMonth -= 1;
    },
  },
};
</script>
