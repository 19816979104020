<template>
  <div class="view--workers">
    <header-filter
      @updateParent="updateFilter"
      icon="people"
      ref="headerFilter"
    >
    </header-filter>
    <standard-table ref="table" @updateTotal="updateTotal"> </standard-table>
  </div>
</template>

<script>
import StandardTable from '@/components/Tables/WorkersTable.vue';
import HeaderFilter from '@/components/Filter/WorkersHeaderFilter.vue';

export default {
  name: 'workers-data',
  components: {
    HeaderFilter,
    StandardTable,
  },
  data() {
    return {
      tableData: null,
      filter: {},
      loading: false,
      dataExport: {},
      count: null,
      workcenterId: this.$store.state.projectId,
    };
  },
  mounted() {
    if (this.$store.state.projectId === null) {
      this.$router.push({ name: 'SelectWorkCenter' });
    }
  },

  methods: {

    updateTable() {
      this.$refs.table.updateFilter(this.filter);
    },
    updateTotal(val) {
      this.count = val;
    },
    updateFilter(val) {
      this.filter = val;
    },
  },

  watch: {
    filter: {
      handler() {
        this.updateTable();
      },
      deep: true,
    },
  },

};
</script>
<style lang="scss" scoped>
.view--workers{
  padding-top: 0;
}
</style>
