<template>
  <a
    href="#"
    class="mdtec-card"
    :class="{ 'no-padding': noPadding }"
    v-if="isInteractive"
  >
    <header
      class="mdtec-card--header"
      v-if="$slots.title || $slots.headerActions"
    >
      <div class="mdtec-card--header__title" v-if="$slots.title">
        <slot name="title"></slot>
      </div>
      <div class="mdtec-card--header__actions" v-if="$slots.headerActions">
        <slot name="headerActions"></slot>
      </div>
    </header>
    <main class="mdtec-card--content" v-if="$slots.content">
      <slot name="content"></slot>
    </main>
    <footer
      class="mdtec-card--footer"
      v-if="$slots.footerContent || $slots.footerActions"
    >
      <div class="mdtec-card--footer__content" v-if="$slots.footerContent">
        <slot name="footerContent"></slot>
      </div>
      <div class="mdtec-card--footer__actions" v-if="$slots.footerActions">
        <slot name="footerActions"></slot>
      </div>
    </footer>
  </a>
  <div class="mdtec-card" :class="{ 'no-padding': noPadding }" v-else>
    <header
      class="mdtec-card--header"
      v-if="$slots.title || $slots.headerActions"
    >
      <div class="mdtec-card--header__title" v-if="$slots.title">
        <slot name="title"></slot>
      </div>
      <div class="mdtec-card--header__actions" v-if="$slots.headerActions">
        <slot name="headerActions"></slot>
      </div>
    </header>
    <main class="mdtec-card--content" v-if="$slots.content">
      <slot name="content"></slot>
    </main>
    <footer
      class="mdtec-card--footer"
      v-if="$slots.footerContent || $slots.footerActions"
    >
      <div class="mdtec-card--footer__content" v-if="$slots.footerContent">
        <slot name="footerContent"></slot>
      </div>
      <div class="mdtec-card--footer__actions" v-if="$slots.footerActions">
        <slot name="footerActions"></slot>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'MdtecPanel',
  props: {
    noPadding: {
      type: Boolean,
      default: false,
    },
    verticalSpaceBetween: {
      type: Boolean,
      default: false,
    },
    isInteractive: {
      type: Boolean,
      default: false,
    },
  },

};
</script>

<style lang="scss" scoped>
//Card common classes
.mdtec-card {
  border-radius: calc(var(--base) * 0.5);
  border: solid 1px rgba(var(--bodyColor), 0.2);
  // overflow: hidden;
  padding: calc(var(--base) * 1.75) calc(var(--base) * 1);
  padding-bottom: calc(var(--base) * 3);
  display: flex;
  flex-direction: column;
  gap: calc(var(--base) * 2);
  background-color: rgb(var(--bodyBg));
  overflow: hidden;
  @media (min-width: 1024px) {
    padding: calc(var(--base) * 1.75) calc(var(--base) * 2.625);
    padding-bottom: calc(var(--base) * 3);
  }
  &.no-padding {
    padding: 0;
  }
  .vertical-space-between {
    align-items: space-between;
  }
  .mdtec-card--header {
    display: flex;
    flex-direction: column;
    gap: calc(var(--base) * 2);
    width: 100%;
    @media (min-width: 1024px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      min-height: calc(var(--base) * 4.5);
    }
    &__title {
      font-size: calc(var(--base) * 2.5);
      line-height: calc(var(--base) * 3);
      font-weight: 500;
      width: 100%;
    }
    &__actions {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      gap: calc(var(--base) * 1);
      @media (min-width: 1024px) {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        min-height: calc(var(--base) * 4.5);
      }
    }
  }
  .mdtec-card--content {
    display: flex;
    flex-direction: column;
  }
  .mdtec-card--footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: calc(var(--base) * 2.5) 0;

    &__actions {
      display: flex;
      flex-direction: column-reverse;
      gap: calc(var(--base) * 2);
      @media (min-width: 1024px) {
        flex-direction: column-reverse;
        justify-content: space-between;
      }
    }
  }
}
</style>
