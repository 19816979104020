<template>
    <v-select v-model="internalValue" :items="options" :label="label" :prepend-inner-icon="icon"
      :color="$getColor('vuetifyColor1')" :item-color="$getColor('vuetifyColor1')" outlined
      :disabled="disabled" :dense="dense" :hide-details="true">
    </v-select>
</template>
<script>

export default {
  name: 'properties-select',
  props: {
    value: null,
    label: {
      type: String,
      default: 'Project type',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    propertyValue: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    options: [],
    internalValue: null,
  }),
  created() {
    this.getItems();
    if (this.value) {
      this.internalValue = this.value;
    }
  },
  watch: {
    internalValue() {
      this.updateParent();
    },
    value() {
      if (this.value !== this.internalValue && this.value) {
        this.internalValue = this.value;
      }
    },
  },
  methods: {
    updateParent() {
      this.$emit('updateParent', this.internalValue);
    },
    resetValue() {
      this.internalValue = null;
    },
    getItems() {
      const allOptions = this.$getValues(this.propertyValue);
      const options = Object.keys(allOptions).map((key) => ({
        value: key,
        text: allOptions[key],
      }));
      this.options = options.map((option) => ({
        value: option.value,
        text: option.text.label,
      }));
    },
  },
};
</script>
