<template>
  <div class="view-layout view-login">
    <main class="login--left-side">
      <div class="login-logo">
        <img alt="app-logo" width="250px" height="50px" src="@/assets/logo.png" />
        <section-header first="Portal de acceso" second="N2 Digital Inputs" />
      </div>
      <form id="loginForm" @submit.prevent="validateUser">
        <v-text-field
          label="Usuario"
          :color="$getColor('vuetifyColor1')"
          placeholder="Correo electrónico"
          outlined
          id="username"
          v-model="$v.input.username.$model"
          type="text"
          :disabled="sending"
          name="username"
          :rules="[$v.input.username.required]"
        >
        </v-text-field>
        <v-text-field
          label="Contraseña"
          :color="$getColor('vuetifyColor1')"
          placeholder="********"
          outlined
          id="password"
          v-model="input.password"
          :disabled="sending"
          name="password"
          autocomplete="on"
          :rules="[$v.input.password.required]"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
        >
        </v-text-field>
        <RecoverPassword />
        <v-btn
          :color="$getColor('vuetifyColor1')"
          dark
          block
          type="submit"
          :loading="sending"
          x-large
          depressed
        >
          <v-icon left>login</v-icon>
          INICIAR SESIÓN
        </v-btn>
      </form>
    </main>
    <aside class="login--right-side" v-if="this.$vuetify.breakpoint.width >= 1024">
      <div class="aside-image">
        <img src="@/assets/images/basic-desktop_big.jpg" alt="" />
      </div>
    </aside>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import RecoverPassword from '@/views/_FATDA/components/RecoverPassword.vue';
import SectionHeader from '@/components/Headers/SectionHeader.vue';

export default {
  name: 'login-view',
  mixins: [validationMixin],
  data() {
    return {
      input: {
        username: '',
        password: '',
      },
      message: '',
      sending: false,
      showPasswordRecovery: false,
      passwordRecoveryEmail: '',
      showPassword: false,
    };
  },
  components: {
    RecoverPassword,
    SectionHeader,
  },
  validations: {
    input: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },

  methods: {
    isMobile() {
      return window.innerWidth < 1024;
    },

    validateUser() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.handleLogin();
      }
    },
    handleLogin() {
      this.sending = true;
      const user = {};
      user.username = this.input.username;
      user.password = this.input.password;

      this.loading = true;

      this.$store.dispatch('auth/login', user).then(
        () => {
          this.$router.push('/');
        },
        (error) => {
          this.loading = false;
          if (error.response.status === 403) {
            this.message = 'Not valid username or password';
          } else {
            this.message = (error.response
              && error.response.data
              && error.response.data.message)
              || error.message
              || error.toString();
          }
          this.sending = false;
        },
      );
    },
    handleEmailRecovery() {
      this.sending = true;
      this.loading = true;

      this.$store
        .dispatch('auth/recoverPassword', this.passwordRecoveryEmail)
        .then(
          () => {
            this.loading = false;
            this.message = 'Email with recovery link was sent';
            this.sending = false;
          },
          (error) => {
            this.loading = false;
            this.message = (error.response
              && error.response.data
              && error.response.data.message)
              || error.message
              || error.toString();
            this.sending = false;
          },
        );
    },
  },
};
</script>
<style lang="scss" scoped>
// * {
//   border: solid 1px green;
// }
.view-login {
  padding:0;
  grid-column: 1 / span 6;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding:0 !important;
  height:var(--layout-height);
  @media (min-width: 1024px) {
    grid-column: 1 / span 12;
    grid-template-columns: repeat(2, 1fr);
  }
  .login--left-side {
    grid-column: 1 / span 1;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0 calc(var(--base) * 4);
    // padding-right:calc(var(--base) * 4);
    @media (min-width: 1024px) {
      padding: 0 calc(var(--base) * 8);
    }
    .login-logo {
      margin-top: calc(var(--layout-height) * 0.2);
      margin-bottom: calc(var(--layout-height) * 0.1);
      img {
        max-width: 250px;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
    #loginForm{
      width:100%;
      margin-bottom: calc(var(--layout-height) * 0.1);
    }
  }
  .login--right-side{
    grid-column: 2 / span 1;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .aside-image{
      width:100%;
      height:100%;
      img{
        width:100%;
        height:100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
// .login-logo {
//   margin-top: calc(var(--base) * 16);
//   margin-bottom: calc(var(--base) * 4);
//   @media (min-width: 1024px) {
//     margin-top: calc(var(--app-height) * 0.2);
//     margin-bottom: calc(var(--app-height) * 0.2);
//   }
//   .first-line {
//     font-size: calc(var(--base) * 10);
//     font-weight: 600;
//     line-height: 1;
//     letter-spacing: -0.03em;
//     margin: 0;
//     padding: 0;
//     margin-left: -0.08em;
//     sup {
//       font-weight: 500;
//     }
//   }
//   .second-line {
//     margin: 0;
//     padding: 0;
//     font-size: calc(var(--base) * 2);
//     font-weight: 300;
//     letter-spacing: -0.03em;
//   }
// }
// .login-buttons {
//   margin-top: auto;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: center;
//   margin-bottom: calc(var(--base) * 12);
//   row-gap: calc(var(--base) * 2);
//   @media (min-width: 1024px) {
//     margin-bottom: calc(var(--base) * 24);
//   }
// }
// .main-container {
//   max-height: var(--app-height);
//   .main-container--inner {
//     max-height: var(--app-height);
//   }
// }
</style>
