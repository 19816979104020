<template>
  <button
    v-bind:class="{ selected: isSelected, enabled: isEnabled, disabled: isDisabled, colorRed: color === 'red', colorBlue: color === 'blue', colorGreen: color === 'green',  colorYellow: color === 'yellow', colorOrange: color === 'orange', colorPurple: color === 'purple' }"
    class="check-selector"
  >
    <div class="icon" v-if="isSelected">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.66669 8L6.66669 12L13.3334 4"
          stroke="#FF0000"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div class="content">
      <div class="first-line">{{ title }}</div>
      <div class="second-line" v-if="isEnabled && !isDisabled">{{ subtitle }}</div>
      <div class="second-line" v-if="!isEnabled">No disponible</div>
      <div class="second-line" v-if="isDisabled && isEnabled">Día bloqueado</div>

    </div>
  </button>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    subtitle: {
      type: String,
      default: 'Subtitle',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    enabled: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    color: {
      type: String,
      default: 'red',
    },
  },
  data() {
    return {
      isSelected: this.selected,
      isEnabled: this.enabled,
      isDisabled: this.disabled,
    };
  },
  watch: {
    disabled(val) {
      this.updateDisabled(val);
    },
  },
  methods: {
    updateEnabled(val) {
      this.isEnabled = val;
    },
    updateDisabled(val) {
      this.isDisabled = val;
    },
    updateSelected(val) {
      this.isSelected = val;
    },
  },

};
</script>

<style lang="scss" scoped>
.check-selector {
  width: auto;
  border-radius: 50000px;
  display: flex;
  column-gap: var(--base);
  justify-content: center;
  align-items: center;
  padding: calc(var(--base) * 2);
  padding-top:calc(var(--base)*1.6);
  pointer-events: none;
  background-color: rgba(var(--bodyColor), 0.06);
  color: rgba(var(--bodyColor), 0.2);
  border: 1px solid rgba(var(--bodyColor), 0);
  text-align: center;
  min-width: 130px;
  &.enabled {
    pointer-events: all;
    cursor: pointer;
    background-color: rgba(#090909, 0.03);
    color: rgba(var(--bodyColor), 0.6);
    border: 1px solid rgba(var(--bodyColor), 0.1);
  }
  &.disabled{
    opacity: 0.6;
    pointer-events: none;
  }
  &.selected {
    color: var(--bodyColor);
    border: 1px solid rgba(var(--bodyColor), 0.1);
    &.colorRed{
      background-color: rgba(var(--colorFatdaRed), 0.2);
      .icon{
        svg{
          path{
            stroke: rgba(var(--bodyColor),0.6);
          }
        }
      }
    }
    &.colorYellow{
      background-color: rgba(var(--colorFatdaYellow), 0.12);
      .icon{
        svg{
          path{
            stroke: rgba(var(--bodyColor),0.6);
          }
        }
      }
    }
    &.colorBlue{
      background-color: rgba(var(--colorFatdaBlue), 0.2);
      .icon{
        svg{
          path{
            stroke: rgba(var(--bodyColor),0.6);
          }
        }
      }
    }
    &.colorGreen{
      background-color: rgba(var(--colorFatdaGreen), 0.2);
      .icon{
        svg{
          path{
            stroke: rgba(var(--bodyColor),0.6);
          }
        }
      }
    }
    &.colorOrange{
      background-color: rgba(var(--colorFatdaOrange), 0.2);
      .icon{
        svg{
          path{
            stroke: rgba(var(--bodyColor),0.6);
          }
        }
      }
    }
    &.colorPurple{
      background-color: rgba(var(--colorFatdaPurple), 0.2);
      .icon{
        svg{
          path{
            stroke: rgba(var(--bodyColor),0.6);
          }
        }
      }
    }

  }
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top:calc(var(--base)*0.4);
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .first-line {
      font-size: calc(var(--base) * 1.75);
      line-height: calc(var(--base) * 2.5);
    }
    .second-line {
      margin: 0;
      padding: 0;
      font-size: calc(var(--base) * 1.5);
      line-height: 1;
    }
  }
}
</style>
