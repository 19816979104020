<template>
    <div></div>
</template>

<script>

export default {
  name: 'Home-Index',
  data() {
    return {
      userId: null,
      adminAvailable: false,
    };
  },
  mounted() {
    if (this.$store.getters['auth/hasRole'](['ROLE_TRABAJADOR'])) {
      this.$router.push({ name: 'ConfigureTime' });
    } else {
      this.$router.push({ name: 'Home' });
    }
  },
};
</script>
