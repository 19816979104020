/* eslint-disable class-methods-use-this */

class ConfigureTimeService {
  getAbsencesOptions(options) {
    return options.filter((option) => option.category === 'absences');
  }

  getAbsencesOptionsForUser(options) {
    let totalAbsences = options.filter((option) => option.category === 'absences');
    // filter when option.isAdmin = false
    totalAbsences = totalAbsences.filter((option) => option.admin === false);
    return totalAbsences;
  }

  getVariablesOptions(options) {
    return options.filter((option) => option.category === 'variables');
  }

  getVariablesOptionsForUser(options) {
    const totalVariables = options.filter((option) => option.category === 'variables');
    // filter when option.isAdmin = false
    // totalVariables = totalVariables.filter((option) => option.admin === false);
    return totalVariables;
  }

  goToCodeConfiguration(id) {
    this.$router.push({ name: 'CodeConfiguration', params: { id } });
  }

  formatDate(date) {
    // return date in format Jueves, 11 de Enero, 2021
    const options = {
      weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
    };
    const inputDate = new Date(date);
    // return with capitalized first letter
    return inputDate.toLocaleDateString('es-ES', options).charAt(0).toUpperCase() + inputDate.toLocaleDateString('es-ES', options).slice(1);
  }

  isWeekend(date) {
    const day = new Date(date).getDay();
    return !!(day === 6 || day === 0);
  }
}

export default new ConfigureTimeService();
