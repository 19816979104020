<template>
  <div class="view--admin-calendar">
    <div class="dashboard-toolbar mdtec-card full-width">
      <div
        class="mdtec-card--header__title"
        v-if="this.$vuetify.breakpoint.width < 1024"
      >
        Resúmen
      </div>
      <WorkcenterSelectorNav />
      <div class="dashboard-toolbar--insights">
        <div class="dashboard-toolbar--field-group insight-item">
          <div class="dashboard-toolbar--field-group__label">
            <v-icon small>mdi-account-group</v-icon>
            Personal:
          </div>
          <div class="dashboard-toolbar--field-group__value">
            <div class="dashboard-toolbar--field-group__value__text">
              <span class="number">{{ totalUsers }}</span>
              <span class="unit">empleados/as</span>
            </div>
          </div>
        </div>
        <div class="dashboard-toolbar--field-group insight-item">
          <div class="dashboard-toolbar--field-group__label">
            <v-icon small color="primary">mdi-alert-circle</v-icon>
            Incidencias sin resolver:
          </div>
          <div class="dashboard-toolbar--field-group__value text-red">
            <div class="dashboard-toolbar--field-group__value__text">
              <span class="number">{{ totalIncidences }}</span>
              <span class="unit">incidencias</span>
            </div>
            <v-icon size="32" color="primary">mdi-alert-circle</v-icon>
          </div>
        </div>

        <!--
          <div class="dashboard-toolbar--field-group insight-item">
          <div class="dashboard-toolbar--field-group__label">
            <v-icon small color="success">mdi-check-circle</v-icon>
            Incidencias resueltas:
          </div>
          <div class="dashboard-toolbar--field-group__value text-green">
            <div class="dashboard-toolbar--field-group__value__text">
              <span class="number">{{ totalSolved }}</span>
              <span class="unit">incidencias</span>
            </div>
          </div>
        </div>
        -->
      </div>
      <div class="dashboard-toolbar--actions">
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="goToAdminCalendar()"
            >
              <v-icon x-large dark>mdi-view-dashboard-variant-outline</v-icon>
            </v-btn>
          </template>
          <span>Ver vista expandida</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              v-bind="attrs"
              v-on="on"
              large
              @click="goToGenerateN2()"
            >
              <v-icon dark>functions</v-icon>
            </v-btn>
          </template>
          <span>Generar N2</span>
        </v-tooltip>
      </div>
    </div>
    <div class="left-side">
      <mdtec-panel>
        <template v-slot:title>Personal</template>
        <template v-slot:content>
          <div class="select-worker" v-if="!selected">
            Selecciona un trabajador/a
          </div>
          <div class="admin-calendar--users--list">
            <v-treeview
              :active.sync="active"
              :items="users"
              item-key="id"
              item-text="fullName"
              :open.sync="open"
              activatable
              open-all
              open-on-click
              transition
            >
              <template v-slot:prepend="{ item }">
                <v-icon v-if="!item.children"> mdi-account </v-icon>
              </template>
            </v-treeview>
          </div>
        </template>
      </mdtec-panel>
    </div>
    <div class="right-side">
    <div class="dashboard-panels--tabs mdtec-card no-padding">
        <v-tabs centered grow background-color="white" color="primary" light persistent>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-1"  @click="isCalendarView = true" v-if="this.$store.state.availableSchedule">
            <v-icon class="mr-3">mdi-calendar</v-icon>
            <span>Cuadrantes</span>
          </v-tab>
          <v-tab href="#tab-2"  @click="isCalendarView = false">
            <v-icon class="mr-3">mdi-clock-outline</v-icon>
            <span>Fichajes</span>
          </v-tab>
        </v-tabs>
      </div>
      <mdtec-panel>
        <template v-slot:title v-if="!selected"> Calendario </template>
        <template v-slot:title v-else>
          Calendario de
          <span class="red--text text--accent-2">{{
            selectedUser.fullName
          }}</span>
        </template>
        <template v-slot:headerActions v-if="selected">
          <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  color="primary"
                  dark
                  icon
                  @click="isDetailView = !isDetailView"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ !isDetailView ? 'view_list' : 'mdi-calendar-account' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ !isDetailView ? 'Ver cuadrante en formato lista' : 'Ver cuadrante en formato calendario' }}</span>
            </v-tooltip>
          <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  color="primary"
                  dark
                  icon
                  @click="goToWorkerPanel()"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-account-cog</v-icon>
                </v-btn>
              </template>
              <span>Ir a panel de Trabajador</span>
            </v-tooltip>
          <v-btn
            depressed
            color="primary"
            dark
            @click="goToEditWorker()"
            v-show="isCalendarView"
          >
            <v-icon small class="mr-2">edit_calendar</v-icon>
            Editar Cuadrante
          </v-btn>
          <v-btn
            depressed
            color="primary"
            dark
            @click="download()"
            v-show="!isCalendarView"
          >
            <v-icon small class="mr-2">mdi-download</v-icon>
            Descargar fichajes
          </v-btn>
          <div v-show="!isCalendarView">
            <CreateClockEntryAdmin v-if="isAdmin"
            :isAdmin="true" ref="createClockEntryAdmin" ></CreateClockEntryAdmin >
          </div>
        </template>
        <template v-slot:content>
          <v-progress-linear
            v-if="loading"
            :color="$getColor('vuetifyColor1')"
            class="linear-progress"
            indeterminate
          >
          </v-progress-linear>
          <div class="mt-3" v-if="!loading && !active[0]">
            Selecciona un trabajador/a...
          </div>
          <v-scroll-y-transition mode="out-in">
            <div class="card-content" v-show="!loading && active[0]">
              <div class="calendar">
                <div class="calendar-navigation">
                  <h4
                    v-if="$refs.calendar"
                    class="calendar-selector red--text text--accent-2"
                  >
                    {{ $refs.calendar.title }}
                  </h4>
                  <v-btn fab text small color="grey darken-2" @click="prev">
                    <v-icon small> mdi-chevron-left </v-icon>
                  </v-btn>
                  <v-btn fab text small color="grey darken-2" @click="next">
                    <v-icon small> mdi-chevron-right </v-icon>
                  </v-btn>
                </div>
                <div v-show="isCalendarView && isDetailView">
                  <WorkerSchedule ref="workerSchedule" @refreshWorkerScheduleData="refreshWorkerScheduleData" @backToCalendar="backToCalendar()" />
                </div>
                <div v-show="!isCalendarView && isDetailView">
                  <WorkerClockEntries ref="workerClockEntries" @backToCalendar="backToCalendar()" />
                </div>
                <v-calendar
                  v-show="isCalendarView && !isDetailView"
                  ref="calendar"
                  v-model="focus"
                  :color="$getColor('vuetifyColor3')"
                  :events="events"
                  :event-color="getEventColor"
                  :type="type"
                  locale="es"
                  :weekdays="weekdays"
                  @click:event="showEvent"
                  @change="updateRange"
                  :interval-count="0"
                >
                  <template v-slot:event="{ event }">
                    <div class="event-chip" :class="event.blocked ? 'blocked' : ''">
                      <span style="font-weight: 600;" >{{ event.type }}</span>
                      <span style="font-weight: 400;" class="pl-1" v-if="event.type && event.extraHours.length > 0">
                        Uds. {{ event.extraHours }}
                    </span>
                    </div>
                  </template>
                </v-calendar>
                <v-calendar
                v-show="!isCalendarView && !isDetailView"
                  ref="calendarClockEntries"
                  v-model="focus"
                  :color="$getColor('vuetifyColor3')"
                  :events="eventsClock"
                  :event-color="getEventColor"
                  :type="type"
                  locale="es"
                  :weekdays="weekdays"
                  @change="updateRange"
                  :interval-count="0"
                >
                  <template v-slot:event="{ event }">
                    <div class="event-chip" :class="event.blocked ? 'blocked' : ''">
                      <span style="font-weight: 600;" >{{ event.type }}</span>
                      <span style="font-size: small;">{{ ":          " + trimDate(event.timeLocale) }}</span>
                    </div>
                  </template>
                </v-calendar>
                <v-menu
                  v-model="selectedOpen"
                  :close-on-content-click="false"
                  :activator="selectedElement"
                  offset-x
                >
                  <v-card
                    color="grey lighten-4"
                    min-width="390px"
                    max-width="720px"
                    flat
                  >
                    <v-toolbar
                      :color="selectedEvent.color"
                      :dark="false"
                      :elevation="0"
                    >
                      <span v-if="selectedEvent.blocked" class="blocked--day pr-3" style="font-size: 25px;">🔒</span>
                      <v-toolbar-title
                      >{{ selectedEvent.name }}</v-toolbar-title>
                      <v-toolbar-title
                      >{{ selectedEvent.type }}</v-toolbar-title>
                      <v-icon style="margin-left: 0.5rem">{{
                        selectedEvent.icon
                      }}</v-icon>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="selectedOpen = false">
                        <v-icon>close</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <div class="card-field-group">
                        <div class="card-field two-col">
                          <div class="card-field__label">Fecha:</div>
                          <div class="card-field__value">
                            <v-icon>mdi-calendar</v-icon>
                            {{ formatDateWithTime(selectedEvent.start) }}
                          </div>
                        </div>
                        <div class="card-field two-col">
                          <div class="card-field__label">Tipo:</div>
                          <div class="card-field__value">
                            <v-icon>{{ selectedEvent.icon }}</v-icon>
                            {{ selectedEvent.type }}
                          </div>
                        </div>
                      </div>
                      <v-divider></v-divider>
                      <div class="card-field-group" v-if="selectedEvent.codes">
                        <div class="card-field">
                          <div class="card-field__label">Códigos:</div>
                          <div class="card-field__value codes-list">
                            <selection-card
                              v-for="code in selectedEvent.codes"
                              :key="code.id"
                              :name="code.name"
                              :class="code.autoVariable ? 'swiper-slide customGreen' : 'swiper-slide customRed'"
                              size="large"
                              :description="code.code"
                              disableMouse
                              selected
                            />
                          </div>
                        </div>
                      </div>

                    </v-card-text>
                    <v-card-actions class="justify-center">
                      <v-btn
                        :color="selectedEvent.color"
                        block
                        @click="openCreateIncidence()"
                        x-large
                      >
                        <v-icon>mdi-alert-circle</v-icon> Crear Incidencia
                      </v-btn>
                    </v-card-actions>
                    <v-card-actions class="justify-center">
                      <v-btn
                        :color="selectedEvent.color"
                        block
                        @click="changeDayBlockedStatus(selectedEvent.id)"
                        x-large
                        dark
                      >
                        <v-icon v-if="selectedEvent.blocked">mdi-lock-open</v-icon>
                        <v-icon v-else>mdi-lock</v-icon>
                        <span v-if="selectedEvent.blocked">Desbloquear día </span>
                        <span v-else>Bloquear día</span>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </div>
            </div>
          </v-scroll-y-transition>
        </template>
      </mdtec-panel>
      <mdtec-panel>
        <template v-slot:title v-if="selectedUser"
          >Incidencias de <span class="red--text text--accent-2">{{ selectedUser.fullName }}</span></template
        >
        <template v-slot:title v-else
          >Incidencias</template
        >
        <template v-slot:headerActions v-if="selected">
          <CreateIncidence v-show="incidenceAvailable" ref="createIncidence" @newIncidence="updateIncidences()"></CreateIncidence>
        </template>
        <template v-slot:content >
          <Incidences
            ref="workerIncidences"
            :isWorker="true"
            v-show="selectedUser"
          />
          <div class="select-worker" v-if="!selectedUser">
            Seleccionar un trabajador/a
          </div>
        </template>
      </mdtec-panel>
    </div>
  </div>
</template>

<script>

import scheduleService from '@/services/schedule.service';
import workcenterService from '@/services/workcenter.service';
import Incidences from '@/views/_FATDA/components/Incidences.vue';
import CreateIncidence from '@/views/_FATDA/components/CreateIncidence.vue';
import MdtecPanel from '@/components/Cards/MdtecPanel.vue';
import WorkcenterSelectorNav from '@/views/_FATDA/components/WorkcenterSelectorNav.vue';
import WorkerSchedule from '@/views/_FATDA/components/WorkerSchedule.vue';
import WorkerClockEntries from '@/views/_FATDA/components/WorkerClockEntries.vue';
import CreateClockEntryAdmin from '@/views/_FATDA/components/CreateClockEntryAdmin.vue';
import SelectionCard from '@/components/Cards/CodeCard.vue';

export default {
  name: 'admin-calendar-view',
  components: {
    CreateIncidence,
    MdtecPanel,
    Incidences,
    WorkcenterSelectorNav,
    SelectionCard,
    WorkerSchedule,
    WorkerClockEntries,
    CreateClockEntryAdmin,
  },
  data() {
    return {

      focus: '',
      type: 'month',
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      items: null,
      loading: false,
      loaded: true,

      n2current: [],
      users: [],
      active: [],
      open: [],

      activeId: null,
      minDate: null,
      maxDate: null,

      workcenterId: this.$store.state.projectId,
      totalIncidences: 0,
      incidenceAvailable: false,

      isDetailView: false,

      // clock entries
      clockEntriesCurrent: [],
      eventsClock: [],
      isCalendarView: true,

      isAdmin: false,

    };
  },
  created() {
    this.isAdmin = this.$store.getters['auth/hasRole'](['ROLE_SUPER_ADMIN']);
  },
  mounted() {
    if (this.$store.state.projectId === null) {
      this.$router.push({ name: 'SelectWorkCenter' });
    } else {
      if (!this.$store.state.availableSchedule) {
        this.isCalendarView = false;
      }
      this.getUsers();
      this.getIncidences();
    }
  },

  methods: {
    trimDate(fechaString) {
      // Extraer la parte de la hora eliminando todo antes de la coma y el espacio vacío
      const horaParte = fechaString.split(',')[1].trim();
      return horaParte;
    },
    changeDayBlockedStatus(id) {
      this.loading = true;
      scheduleService
        .changeDayBlockedStatus(id)
        .then(() => {
          this.loading = false;
          this.updateUserSchedule(this.selected);
          this.selectedOpen = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async getUsers() {
      this.loading = true;
      await workcenterService.getWorkers({ size: 999, workCenter: this.$store.state.projectId, direction: 'asc' }).then((response) => {
        this.users = response.content.map((user) => ({
          id: user.id,
          name: user.name,
          lastName1: user.lastName1,
          email: user.email,
          fullName: `${user.name} ${user.lastName1}`,
        }));
        this.loading = false;
      });
    },
    async getIncidences() {
      this.loading = true;
      await scheduleService
        .countUnsolvedIncidencesByWorkCenter(this.$store.state.projectId)
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          this.totalIncidences = response;
        });
    },
    updateUserSchedule(id) {
      this.loading = true;
      const dateFrom = new Date(this.minDate.date).toISOString().split('T')[0];
      const dateTo = new Date(this.maxDate.date).toISOString().split('T')[0];
      scheduleService.getDaysInRange(id, dateFrom, dateTo).then((response) => {
        this.n2current = response;
        this.updateRangeN2();
        const obj = {
          date: this.focus,
          id: this.selectedDayId,
        };
        this.updateCreateIncidence(obj);
        this.loading = false;
      });

      const maxDateClockEntries = new Date(dateTo);
      // Añadir un día y restar un milisegundo
      maxDateClockEntries.setDate(maxDateClockEntries.getDate() + 1);
      maxDateClockEntries.setMilliseconds(maxDateClockEntries.getMilliseconds() - 1);
      // new parse date
      // Restar un milisegundo para obtener el último momento del mes actual
      maxDateClockEntries.setMilliseconds(maxDateClockEntries.getMilliseconds() - 1);
      // Crear la fecha final en el formato ISO y obtener solo la parte de la fecha
      const dateToClockEntries = maxDateClockEntries.toISOString();
      // end new parse date

      scheduleService.getClockEntriesInRange(id, dateFrom, dateToClockEntries).then((response) => {
        this.clockEntriesCurrent = response;
        this.updateRangeClockEntries();
      });
    },
    getDayStyle(date) {
      const event = this.events.find((e) => e.start === date);
      if (event) {
        return {
          backgroundColor: event.color,
        };
      }
      return {};
    },
    formatDateWithTime(date) {
      const d = new Date(date);
      const month = `0${d.getMonth() + 1}`.slice(-2);
      const day = `0${d.getDate()}`.slice(-2);
      return `${day}/${month}/${d.getFullYear()}`;
    },
    goToWorkerPanel() {
      this.$router.push({
        name: 'WorkerPanel',
        params: { id: this.selected },
      });
    },
    goToGenerateN2() {
      this.$router.push({
        name: 'GenerateN2',
      });
    },
    goToAdminCalendar() {
      this.$router.push({
        name: 'AdminCalendar',
      });
    },
    goToEditWorker() {
      this.$router.push({
        name: 'ConfigureTimeAdmin',
        params: { id: this.selected },
      });
    },

    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = '';
    },
    prev() {
      this.loading = true;
      this.$refs.calendar.prev();
    },
    next() {
      this.loading = true;
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      // set calendar focus to event date
      this.focus = event.date;
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        // eslint-disable-next-line no-return-assign
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true));
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRangeN2() {
      if (this.loaded) {
        const events = [];
        // items foreach
        this.n2current.forEach((item) => {
          events.push({
            id: item.id,
            blocked: item.blocked,
            start: new Date(item.date),
            end: new Date(item.date),
            date: item.date,
            type: item.type ? this.$getAppointmentTypeValues(item.type).label : 'Sin completar',
            longType: item.type ? this.$getAppointmentTypeValues(item.type).longLabel : 'Sin completar',
            color: item.type ? this.$getAppointmentTypeValues(item.type).color : 'white',
            icon: item.type ? this.$getAppointmentTypeValues(item.type).icon : 'mdi-calendar',
            codes: item.codes,
            participants: null,
            timed: true,
            extraHours: item.extraHours,
          });
        });
        this.events = events;
        this.$refs.workerSchedule.updateSchedule(events);
      }
    },
    updateRangeClockEntries() {
      if (this.loaded) {
        const eventsCl = [];
        // items foreach
        this.clockEntriesCurrent.forEach((item) => {
          eventsCl.push({
            id: item.id,
            blocked: false,
            start: new Date(item.clockInTime),
            end: new Date(item.clockInTime),
            date: item.clockInTime,
            unixClockInTime: item.unixClockInTime,
            sourceType: item.type,
            manualEntry: item.manualEntry,
            adminEntry: item.adminEntry,
            time: scheduleService.unixTimeToDateTime(item.unixClockInTime),
            timeLocale: scheduleService.unixTimeToLocaleDateTime(item.unixClockInTime),
            type: item.type ? this.$getclockEntryTypeValues(item.type).label : 'N/A',
            // color: item.type ? this.$getclockEntryTypeValues(item.type).color : 'grey',
            color: this.setClockEntryColor(item),
            timed: false,
            icon: item.type ? this.$getclockEntryTypeValues(item.type).icon : 'mdi-clock',
            workerId: item.worker.id,
            workerName: item.worker.name,
            workerLastName: item.worker.lastName1,
            workerEmail: item.worker.email,
          });
        });
        this.eventsClock = eventsCl;
        this.$refs.workerClockEntries.updateSchedule(eventsCl);
        if (this.isAdmin) {
          this.$refs.createClockEntryAdmin.updateWorker(this.selectedUser);
        }
      }
    },
    setClockEntryColor(item) {
      if (item.adminEntry) {
        return '#bd45d1';
      }
      if (item.manualEntry) {
        return 'success';
      }
      if (item.type) {
        return this.$getclockEntryTypeValues(item.type).color;
      }
      return 'grey';
    },
    refreshWorkerScheduleData(activator) {
      if (activator) {
        this.updateUserSchedule(this.selected);
      }
    },
    updateRange(obj) {
      this.minDate = obj.start;
      this.maxDate = obj.end;
      if (this.selected) {
        this.updateUserSchedule(this.selected);
      }
    },

    updateCreateIncidence(obj) {
      this.$refs.createIncidence.updateData(this.selectedUser.fullName, obj);
    },
    resetCreateIncidence() {
      this.$refs.createIncidence.resetData();
    },
    openCreateIncidence() {
      this.$refs.createIncidence.openDialog();
    },

    checkIfDateIsInN2(date) {
      let found = false;
      this.n2current.forEach((item) => {
        if (item.date === date) {
          found = item;
        }
      });
      return found;
    },
    createDay(dateSelected) {
      scheduleService.addDayToWorkerSchedule(
        this.selected,
        {
          date: dateSelected,
          type: null,
          codes: [],
        },
      ).then(() => {
        this.incidenceAvailable = true;
        this.updateUserSchedule(this.selected);
      });
    },
    updateIncidences() {
      this.$refs.workerIncidences.updateWorker(this.selected);
    },
    backToCalendar() {
      this.isDetailView = false;
    },
    download() {
      scheduleService.download(this.eventsClock);
    },

  },

  computed: {
    selected() {
      if (!this.active.length) return undefined;
      const id = this.active[0];
      return id;
    },
    selectedUser() {
      return this.users.find((user) => user.id === this.selected);
    },
    totalUsers() {
      if (!this.users) {
        return 0;
      }
      return this.users.length;
    },
    selectedDayId() {
      const dateIsPresent = this.checkIfDateIsInN2(this.focus);
      if (dateIsPresent) {
        return dateIsPresent.id;
      }
      return null;
    },
  },
  watch: {
    focus(val) {
      if (!val) return;
      this.resetCreateIncidence();
      this.incidenceAvailable = false;
      const dateIsPresent = this.checkIfDateIsInN2(val);
      if (dateIsPresent) {
        this.incidenceAvailable = true;
        this.updateCreateIncidence(dateIsPresent);
      } else if (!this.loading) {
        this.createDay(val);
      }
    },

    selected(val) {
      if (val) {
        this.updateUserSchedule(val);
        this.$refs.workerIncidences.updateWorker(val);
      } else {
        this.active = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.view--admin-calendar {
  grid-column: 1 / span 12;
  padding: 0 calc(var(--base) * 3);
  height: 100%;
  padding-top: calc(var(--base) * 0);
  padding-bottom: calc(var(--base) * 3);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: calc(var(--base) * 1);
  @media (min-width: 1024px) {
    padding-top:0 !important;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: max-content;
    gap: calc(var(--base) * 2);
  }

  .card-title {
    font-size: calc(var(--base) * 2.5);
    font-weight: 400;
  }
  .main-container {
    height: 100%;
    .main-container--inner {
      height: 100%;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: calc(var(--base) * 1);
    }
  }

  .full-width{
    grid-column: 1 / span 12;
    grid-row: 1 / span 1;
  }
  .left-side {
    grid-column: 1 / span 4;
    display: flex;
    flex-direction: column;
    gap: calc(var(--base) * 2);
  }
  @media (max-width: 768px) { /* Este valor puede ajustarse según el tamaño de pantalla que consideres "móvil" */
  .left-side {
    grid-column: 1 / span 12;
  }
  }
  .right-side {
    grid-column: 5 / span 8;
    display: flex;
    flex-direction: column;
    gap: calc(var(--base) * 2);
  }
  @media (max-width: 768px) { /* Este valor puede ajustarse según el tamaño de pantalla que consideres "móvil" */
  .right-side {
    grid-column: 1 / span 12;
  }
  }
  // grid-column: 1 / span 3;
  height: 100%;
  .select-worker {
    font-size: calc(var(--base) * 2);
    margin-bottom: calc(var(--base) * 2);
  }
  .admin-calendar--users--list {
    max-height: 100%;
    //max-height: calc(100vh - 330px);
    //overflow-y: scroll;
    overflow-y: hidden;
  }

  // grid-column: 4 / span 9;
  .calendar {
    width: 100%;
    .calendar-selector {
      text-transform: capitalize;
    }
    .calendar-navigation {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: calc(var(--base) * 1);
      margin-bottom: calc(var(--base) * 1);
      h4.calendar-selector {
        font-size: calc(var(--base) * 2.65);
        font-weight: 400;
        text-transform: capitalize;
      }
    }
    .event-chip {
      display: flex;
      align-items: center;
      width: 100%;
      color: #161616 !important;
      padding: 0 calc(var(--base) * 0.5);
      font-weight: 400;
      justify-content: flex-start;
      &.blocked {
        &:before {
          content: "🔒";
        }
      }
    }
  }

  .two-columns-items {
    display: flex;
    flex-wrap: wrap;
    gap: calc(var(--base) * 4);
  }
}
.card-field-group {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  gap: calc(var(--base) * 2);
  .card-field {
    display: flex;
    flex-direction: column;
    gap: calc(var(--base) * 0.5);
    width: 100%;
    &__value {
      width: 100%;
      min-height: calc(var(--base) * 5);
      background-color: rgb(var(--bodyBg));
      padding: calc(var(--base) * 1) calc(var(--base) * 2);
      padding-left: calc(var(--base) * 1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: calc(var(--base) * 1);
      border-radius: calc(var(--base) * 0.5);
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      ::-webkit-scrollbar {
        display: none;
      }
      &.codes-list {
        overflow-x: scroll;
        background-color: transparent;
        margin-right: calc(var(--base) * 2);
        padding: 0;
        justify-content: flex-start;
      }
    }
  }
}
</style>
<style lang="scss">
.view--admin-calendar .v-treeview-node__level {
  display: none !important;
}
.v-subheader.theme--light {
  padding: 0 !important;
  height: calc(var(--base) * 3) !important;
  margin-bottom: calc(var(--base) * 0.5) !important;
}
.view--admin-calendar .v-toolbar__content {
  padding: 0 !important;
  height: auto !important;
}
.view--admin-calendar .v-calendar-weekly__head-weekday {
  background: white !important;
  border: 1px solid rgba(black, 0.1) !important;
  border-top: none !important;
  &:first-of-type {
    border-left: none !important;
  }
}
.view--admin-calendar .v-divider {
  margin-bottom: calc(var(--base) * 0.5) !important;
  margin-top: calc(var(--base) * 0.5) !important;
}
// .view--admin-calendar .v-treeview-node__root {
//   padding-left: 0 !important;
// }
// .view--admin-calendar .v-treeview-node__content {
//   margin-left: 0 !important;
// }
</style>
<style lang="scss">
.view--admin-calendar {
  & .left-side .mdtec-card {
    height: 100%;
    max-height: calc(100vh - 240px);
    overflow-y: scroll;
  }
}
</style>
<style lang="scss" scoped>
  ::v-deep .v-calendar .v-event{
    margin-left: 2px;
  }
</style>
