<template>
  <div class="container-max-width">
    <section-header first="Histórico de versiones"
      second="Lista de despliegues de nuevas versiones de la aplicación:" />
    <div class="versions_panel-grid">
      <div class="left-side">
        <v-tabs background-color="transparent" :color="$getColor('vuetifyColor1')"
          class="dashboard_box users_panel-tab" grow>
          <v-tab> Última versión</v-tab>
        </v-tabs>
        <v-card class="elevation-2 mt-5 p-4" v-if="loaded">
          <v-card-title class="text-h5">
            Versión: {{ items[0].name }}
          </v-card-title>
          <v-card-title class="text-h6 mt-0 pt-0">
            {{ items[0].date }}
          </v-card-title>
          <v-card-text>
            <span class="text-subtitle-1">Responsable de despliegue: {{ items[0].owner }}</span><br>
            <span class="text-subtitle-2 mt-5">{{ items[0].title }}</span><br>
            <p class="pt-2">{{ items[0].description }}</p>
          </v-card-text>
        </v-card>
      </div>
      <div class="right-side">
        <v-tabs background-color="transparent" :color="$getColor('vuetifyColor1')"
          class="dashboard_box users_panel-tab" grow>
          <v-tab> Histórico </v-tab>
        </v-tabs>
        <v-timeline class="mt-5">
          <v-timeline-item v-for="(version, i) in items" :key="i">
            <span slot="opposite">{{ version.date }} ( {{ version.name }} )</span>
            <v-card class="elevation-1 mx-5 my-3">
              <v-card-title class="text-h6">
                Versión: {{ version.name }}
              </v-card-title>
              <v-card-text>
                Responsable de despliegue: {{ version.owner }}<br>
                Fecha de despliegue: {{ version.date }}<br>
                {{ version.title }}
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>Más info: </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      {{ version.description }}
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </div>
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import adminService from '@/services/admin.service';
import SectionHeader from '@/components/Headers/SectionHeader.vue';

export default {
  name: 'versions-view',
  data() {
    return {
      loading: false,
      loaded: false,
      dataCountLoaded: 0,
      adminAvailable: false,
      items: [],
      count: 0,
      workcenterId: null,
    };
  },
  mounted() {
    this.getVersions();
  },
  methods: {
    getVersions() {
      this.loading = true;
      adminService.getVersions2().then((response) => {
        this.items = response.content.map((ele) => ({
          id: ele.id,
          name: ele.name,
          title: ele.title,
          description: ele.description,
          owner: ele.owner,
          date: this.parsearFecha(ele.creationDate),
        }));
        this.loaded = true;
      });
    },
    parsearFecha(fechaString) {
      // Crear un objeto Date usando la cadena de fecha
      const fecha = new Date(fechaString);

      // Obtener los componentes de la fecha
      const año = fecha.getFullYear();
      const mes = fecha.getMonth() + 1; // Los meses en JavaScript son 0-indexados
      const dia = fecha.getDate();
      const horas = fecha.getHours();
      const minutos = fecha.getMinutes();
      // const segundos = fecha.getSeconds();
      // Formatear la fecha en el formato deseado (puedes ajustar esto según tus necesidades)
      const fechaFormateada = `${año}-${mes < 10 ? `0${mes}` : mes}-${dia < 10 ? `0${dia}` : dia} ${horas < 10 ? `0${horas}` : horas}:${minutos < 10 ? `0${minutos}` : minutos}`;
      return fechaFormateada;
    },
  },
  components: {
    SectionHeader,
  },
};
</script>
<style lang="scss" scoped>
.versions_panel-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1em;

  .left-side {
    grid-column: 1 / span 4;
  }

  .right-side {
    grid-column: 5 / span 8;
  }
}
</style>
