<template>
  <v-dialog v-model="dialog" class="dialog-wrapper" width="650px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed class="" :dark="$getPrimaryButton('dark')" icon
        :rounded="$getPrimaryButton('rounded')" :outlined="$getPrimaryButton('outlined')"
        :color="$getPrimaryButton('color')" v-bind="attrs" v-on="on">
        <v-icon>mdi-information</v-icon>
      </v-btn>
    </template>
    <v-container fluid>
      <v-card outlined class="p-3" :elevation="$getCardOptions('elevation')">
        <v-card-title>
          <section-header first="Información" second="de alertas" />
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <!-- Add v-text-area for description -->

            <v-col>
              <v-row>
                <label class="input-field--label"><v-icon color="success">mdi-alert-decagram</v-icon>
              Entrada/Salida manual de Usuario
            </label>
              </v-row>
              <v-row>
                <label class="input-field--label"><v-icon color="#bd45d1">mdi-security</v-icon>
                  Entrada/Salida manual de Administrador
            </label>
              </v-row>
              <v-row>
                <label class="input-field--label"><v-icon>mdi-alert-circle</v-icon>
                  El día comienza con una Salida o termina con una Entrada
            </label>
              </v-row>
              <v-row>
                <label class="input-field--label"> <v-icon color="orange">mdi-alert-rhombus</v-icon>
              El número de entradas no coincide con el número de salidas
            </label>
              </v-row>
              <v-row>
                <label class="input-field--label"> <v-icon color="red">mdi-alert-box</v-icon>
                Hay entradas o salidas consecutivas
            </label>
              </v-row>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Start button for close dialog -->
          <v-btn depressed :outlined="$getPrimaryButton('outlined')"
            :rounded="$getPrimaryButton('rounded')" large @click="dialog = false">
            Volver
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>

import SectionHeader from '@/components/Headers/SectionHeader.vue';

export default {
  name: 'sap-code-register',
  data: () => ({

    dialog: false,

  }),
  components: {
    SectionHeader,
  },

  methods: {
    openDialog() {
      this.dialog = true;
    },
  },
};
</script>
