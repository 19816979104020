import axios from 'axios';

const API_URL = `${process.env.VUE_APP_API_URL}`;

class AuthService {
  // eslint-disable-next-line class-methods-use-this
  login(user) {
    return axios
      .post(`${API_URL}login`, {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        if (response.data) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  // eslint-disable-next-line class-methods-use-this
  getDecodedJwtToken(token) {
    if (token) {
      return JSON.parse(atob(token.split('.')[1]));
    }
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  logout() {
    localStorage.removeItem('user');
  }

  // eslint-disable-next-line class-methods-use-this
  register(user) {
    return axios.post(`${API_URL}signup`, {
      username: user.username,
      email: user.email,
      password: user.password,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  recoverPassword(email) {
    return axios.post(`${API_URL}recover-password-request`, {
      email,
    });
  }
}

export default new AuthService();
