<template>
  <div>
    <CreateCode class="mb-3 ml-3" v-if="workcenterId" :workcenterId="workcenterId" />
    <Codes class="pt-5" v-if="workcenterId" :workcenterId="workcenterId" />
    <!-- Add progress bar -->
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import CreateCode from '@/views/_FATDA/components/CreateCode.vue';
import Codes from '@/views/_FATDA/components/Codes.vue';

export default {
  name: 'codes-view',
  data() {
    return {
      loading: false,
      loaded: false,
      dataCountLoaded: 0,
      adminAvailable: false,
      items: [],
      count: 0,
      workcenterId: null,
    };
  },
  mounted() {
    if (this.$store.state.projectId === null) {
      this.$router.push({ name: 'SelectWorkCenter' });
    } else {
      this.workcenterId = this.$store.state.projectId;
      this.adminAvailable = this.$store.getters['auth/hasRole'](['ROLE_SUPER_ADMIN']);
    }
  },
  methods: {
  },
  components: {
    CreateCode,
    Codes,
  },
};
</script>
