import { render, staticRenderFns } from "./CodeCard.vue?vue&type=template&id=285411b2&scoped=true"
import script from "./CodeCard.vue?vue&type=script&lang=js"
export * from "./CodeCard.vue?vue&type=script&lang=js"
import style0 from "./CodeCard.vue?vue&type=style&index=0&id=285411b2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "285411b2",
  null
  
)

export default component.exports