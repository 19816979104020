<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th scope="col" class="text-left">Código</th>
          <th scope="col" class="text-left">Nombre</th>
          <th scope="col" class="text-left"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in codes" :key="item.text">
          <td class="table-description">{{ item.code }}</td>
          <td class="table-description">{{ item.name }}</td>
          <td>
            <div class="project-erase">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="item.deleteHide"
                    fab
                    color="rgba(0,0,0,0.56)"
                    text
                    depressed
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="item.deleteHide = false"
                    ><v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Borrar código</span>
              </v-tooltip>
              <v-btn
                v-show="!item.deleteHide"
                fab
                color="rgba(0,0,0,0.56)"
                text
                small
                @click="deleteUserCode(item.id)"
                depressed
                ><v-icon color="teal accent-4">mdi-check-circle-outline</v-icon>
              </v-btn>
              <v-btn
                v-show="!item.deleteHide"
                fab
                color="rgba(0,0,0,0.56)"
                text
                small
                @click="item.deleteHide = true"
                depressed
                ><v-icon :color="$getColor('vuetifyColor1')">mdi-close-circle-outline</v-icon>
              </v-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import workcenterService from '@/services/workcenter.service';

export default {
  name: 'user-codes-table',
  data() {
    return {
      currentIndex: -1,
      searchTitle: '',
      count: 0,
      username: null,
      codes: [],
      workerId: null,
    };
  },

  methods: {
    updateCodes(codes, workerId) {
      this.workerId = workerId;
      this.codes = codes.map((ele) => ({
        id: ele.id,
        code: ele.code,
        name: ele.name,
        deleteHide: true,
      }));
    },
    deleteUserCode(id) {
      workcenterService.deleteN2CodeFromWorker(this.workerId, id)
        .then(() => {
          this.$router.go(0);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.table-description {
  line-height: 1.3;
  padding: 1em !important;
}
.project-erase {
  display: flex;
  flex-wrap: nowrap;

}
</style>
