<template>
  <div class="view-layout view-login">
    <main class="login--left-side">
      <div class="login-logo">
        <img alt="app-logo" width="250px" src="@/assets/logo.png" />
      </div>
      <section-header-with-info
          first="Introduce una clave y confírmala"
          second="para genera la nueva contraseña"
          third="( La clave debe tener al menos 8 caracteres, una mayúscula, una minúscula, un número y un carácter especial )"

      />
      <form id="loginForm" @submit.prevent="validateUser">
        <v-text-field v-model="password" label="Introducir nueva contraseña"
          placeholder="Nueva contraseña..." :rules="passwordRules" required outlined
          :dense="this.$vuetify.breakpoint.width < 1024" :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"></v-text-field>
        <v-text-field v-model="passwordConfirm" label="Confirmar contraseña" placeholder="Repetir contraseña..." :rules="passwordConfirmRules"
          required outlined :dense="this.$vuetify.breakpoint.width < 1024" :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"></v-text-field>
        <v-btn :color="$getColor('vuetifyColor1')" dark block :loading="sending" x-large depressed
        type="submit" :disabled="!isNextButtonEnabled" >
          <v-icon left>vpn_key</v-icon>
          Guardar
        </v-btn>
      </form>
    </main>
    <aside class="login--right-side" v-if="this.$vuetify.breakpoint.width >= 1024">
      <div class="aside-image">
        <img src="@/assets/images/notebook.jpg" alt="" />
      </div>
    </aside>
  </div>

</template>
<script>
import { validationMixin } from 'vuelidate';
import loggedUserService from '@/services/logged-user.service';
import SectionHeaderWithInfo from '@/components/Headers/SectionHeaderWithInfo.vue';

export default {
  name: 'ConfirmPassword',
  mixins: [validationMixin],
  methods: {
    validateUser() {
      if (this.password === this.passwordConfirm && this.password.length >= 8) {
        this.sending = true;
        if (this.$route.query.id) {
          loggedUserService.validateUser(this.password, this.$route.query.cod, this.$route.query.id).then(
            (response) => {
              this.response = response;
              this.$router.push({ name: 'Login' });
              this.sending = false;
            },
            (error) => {
              this.response = error.response.data;
            },
          );
        } else {
          loggedUserService.validateUserResetPassword(this.password, this.$route.query.cod).then(
            (response) => {
              this.response = response;
              this.$router.push({ name: 'Login' });
              this.sending = false;
            },
            (error) => {
              this.response = error.response.data;
            },
          );
        }
      }
    },

  },
  components: {
    SectionHeaderWithInfo,
  },

  data() {
    return {
      response: '',
      isPreviousButtonEnabled: true,
      password: '',
      passwordConfirm: '',
      showPassword: false,
      passwordRules: [
        (v) => !!v || 'Campo requerido',
        (v) => (v && v.length >= 8) || 'Debe tener al menos 8 caracteres',
        (v) => (v && v.length <= 20) || 'Debe tener menos de 20 caracteres',
        (v) => (v && /[A-Z]/.test(v)) || 'Debe tener al menos una mayúscula',
        (v) => (v && /[a-z]/.test(v)) || 'Debe tener al menos una minúscula',
        (v) => (v && /[0-9]/.test(v)) || 'Debe tener al menos un número',
        (v) => (v && /[^A-Za-z0-9]/.test(v)) || 'Debe tener al menos un caracter especial',
      ],
      passwordConfirmRules: [
        (v) => !!v || 'Campo requerido',
        (v) => (v && v.length >= 8) || 'Debe tener al menos 8 caracteres',
        (v) => (v && v.length <= 20) || 'Debe tener menos de 20 caracteres',
        (v) => (v && /[A-Z]/.test(v)) || 'Debe tener al menos una mayúscula',
        (v) => (v && /[a-z]/.test(v)) || 'Debe tener al menos una minúscula',
        (v) => (v && /[0-9]/.test(v)) || 'Debe tener al menos un número',
        (v) => (v && /[^A-Za-z0-9]/.test(v)) || 'Debe tener al menos un caracter especial',
        (v) => (v && v === this.password) || 'Las contraseñas no coinciden',
      ],
      sending: false,

    };
  },
  computed: {

    checkPasswordRulesWithPassword() {
      // check all rules with checkPasswordRules
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.passwordRules.length; i++) {
        if (this.passwordRules[i](this.password) !== true) {
          return false;
        }
      }
      return true;
    },
    checkPasswordConfirmRulesWithPassword() {
      // check all rules with checkPasswordRules
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.passwordConfirmRules.length; i++) {
        if (this.passwordConfirmRules[i](this.passwordConfirm) !== true) {
          return false;
        }
      }
      return true;
    },
    isNextButtonEnabled() {
      // if checkPasswordRulesWithPassword is true and checkPasswordConfirmRulesWithPassword is true
      if (this.checkPasswordRulesWithPassword && this.checkPasswordConfirmRulesWithPassword) {
        return true;
      }
      return false;
    },

  },

};
</script>

<style lang="scss" scoped>
// * {
//   border: solid 1px green;
// }
.view-login {
  padding: 0;
  grid-column: 1 / span 6;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding: 0 !important;
  height: var(--layout-height);

  @media (min-width: 1024px) {
    grid-column: 1 / span 12;
    grid-template-columns: repeat(2, 1fr);
  }

  .login--left-side {
    grid-column: 1 / span 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0 calc(var(--base) * 4);

    // padding-right:calc(var(--base) * 4);
    @media (min-width: 1024px) {
      padding: 0 calc(var(--base) * 8);
    }

    .login-logo {
      margin-top: calc(var(--layout-height) * 0.2);
      margin-bottom: calc(var(--layout-height) * 0.1);

      img {
        max-width: 250px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    #loginForm {
      width: 100%;
      margin-bottom: calc(var(--layout-height) * 0.1);
    }
  }

  .login--right-side {
    grid-column: 2 / span 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .aside-image {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
</style>
