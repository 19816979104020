<template>
    <v-dialog v-model="dialog" class="dialog-wrapper" width="650px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn depressed :dark="$getPrimaryButton('dark')"
          :rounded="$getPrimaryButton('rounded')" :outlined="$getPrimaryButton('outlined')"
          :color="$getPrimaryButton('color')" v-bind="attrs" v-on="on">
          <v-icon left>mdi-file-document-plus</v-icon>
          Añadir código SAP
        </v-btn>
      </template>
    <v-container fluid>
          <v-card outlined class="p-3" :elevation="$getCardOptions('elevation')">
            <v-card-title>
              <section-header
              first="Completa los campos"
              second="y añade el nuevo código SAP"
              />
            </v-card-title>
            <v-card-text>
              <v-form ref="form">
                <label class="input-field--label"> Código SAP: </label>
                <v-text-field v-model="formData.code" placeholder="Código SAP"
                  :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="local_offer">
                </v-text-field>
                <label class="input-field--label"> Nombre: </label>
                <v-text-field v-model="formData.name" placeholder="Nombre"
                  :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="description">
                </v-text-field>
                <label class="input-field--label"> Seleccionar tipo (variables/absentismos): </label>
                <v-select v-model="formData.category" :items="categoryOptions" placeholder="Tipo"
                  :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="border_color">
                </v-select>
                <label class="input-field--label"> Seleccionar si es código de administrador: </label>
                <v-select v-model="formData.admin" :items="isAdminOptions" placeholder="Administración"
                  :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="admin_panel_settings">
                </v-select>
                <label class="input-field--label"> Seleccionar si es código de asignación automática: </label>
                <v-select v-model="formData.autoVariable" :items="isAutoVariableOptions" placeholder="Asignación automática"
                  :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="autorenew">
                </v-select>
                <label v-if="formData.category === 'variables'" class="input-field--label"> Seleccionar si es código de unidades: </label>
                <v-select v-if="formData.category === 'variables'" v-model="formData.extraHour" :items="isExtraHoursOptions" placeholder="Unidades"
                  :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="more_time">
                </v-select>
                <label class="input-field--label"> Seleccionar tipo de importe: </label>
                <v-select v-model="amountType" :items="amountTypeOptions" placeholder="Importe mensual"
                  :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="event_repeat">
                </v-select>
                <label v-if="amountType === 1" class="input-field--label"> Seleccionar importe diario: </label>
                <v-text-field v-if="amountType === 1" v-model="formData.amount" placeholder="Importe diario" type="number"
                  :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="paid">
                </v-text-field>
                <label v-if="amountType === 2" class="input-field--label"> Seleccionar importe mensual: </label>
                <v-text-field v-if="amountType === 2" v-model="formData.fixedMonthlyAmount" placeholder="Importe mensual" type="number"
                  :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="paid">
                </v-text-field>
                <label class="input-field--label"> Seleccionar periodo de cierre de N2: </label>
                <v-select v-model="formData.monthly" :items="isMonthlyOptions" placeholder="Periodo de cierre"
                  :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="pending_actions">
                </v-select>
                <label v-if="formData.category === 'variables'" class="input-field--label"> Seleccionar turno: </label>
                <v-select v-if="formData.category === 'variables'" v-model="formData.type" :items="typeOptions" placeholder="Turno"
                  :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="watch_later">
                </v-select>
                <label v-if="formData.category === 'variables'" class="input-field--label"> Seleccionar festivo/laborable/todos: </label>
                <v-select v-if="formData.category === 'variables'" v-model="formData.weekend" :items="weekendOptions" placeholder="Festivo"
                  :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="weekend">
                </v-select>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="white--text" :rounded="$getPrimaryButton('rounded')" :disabled="$v.formData.$invalid"
                :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')" large @click="newRegister" :loading="loading">
                Añadir código
              </v-btn>
            </v-card-actions>
          </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import SectionHeader from '@/components/Headers/SectionHeader.vue';

import workcenterService from '@/services/workcenter.service';

export default {
  name: 'sap-code-register',
  props: {
    workcenterId: {
      required: true,
    },
  },
  components: {
    SectionHeader,
  },
  data: () => ({
    loading: false,
    mixins: [validationMixin],

    formData: {
      code: null,
      name: null,
      type: null,
      weekend: null,
      category: null,
      admin: null,
      autoVariable: null,
      amount: null,
      monthly: null,
      extraHour: null,
      fixedMonthlyAmount: null,
    },
    dialog: false,
    activeOptions: [{ text: 'Active', value: true }, { text: 'No Active', value: false }],

    categoryOptions: [
      { text: 'Variable', value: 'variables' },
      { text: 'Absentismo', value: 'absences' },
    ],

    typeOptions: [
      { text: 'Turno de mañana/tarde', value: 'morning/evening' },
      { text: 'Turno de Noche', value: 'night' },
      { text: 'Todos', value: 'all' },
    ],

    weekendOptions: [
      { text: 'Festivo', value: '1' },
      { text: 'Laborable', value: '0' },
      { text: 'Cualquiera', value: 'all' },
    ],
    isAdminOptions: [
      { text: 'Sí', value: true },
      { text: 'No', value: false },
    ],
    isAutoVariableOptions: [
      { text: 'Sí', value: true },
      { text: 'No', value: false },
    ],
    isMonthlyOptions: [
      { text: 'Mensual', value: true },
      { text: 'Quincenal', value: false },
    ],
    isExtraHoursOptions: [
      { text: 'Sí', value: true },
      { text: 'No', value: false },
    ],
    amountTypeOptions: [
      { text: 'Sin importe', value: 0 },
      { text: 'Importe diario', value: 1 },
      { text: 'Importe mensual', value: 2 },
    ],
    amountType: null,

  }),

  validations() {
    const valObj = {
      formData: {
        code: { required },
        name: { required },
        category: { required },
        monthly: { required },
        // extraHour: { required },
      },

    };
    return valObj;
  },
  methods: {
    newRegister() {
      this.loading = true;
      this.checkFormData();
      workcenterService.addN2Code(this.workcenterId, this.formData)
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.dialog = false;
          this.$router.go();
        });
    },
    checkFormData() {
      if (this.formData.category === 'absences') {
        this.formData.type = null;
        this.formData.weekend = null;
        this.formData.autoVariable = null;
        this.formData.amount = null;
        this.formData.fixedMonthlyAmount = null;
      }

      // if (this.formData.category === 'variables') {
      //  this.formData.extraHour = null;
      // }

      if (this.amountType === 0) {
        this.formData.amount = null;
        this.formData.fixedMonthlyAmount = null;
      }
      if (this.amountType === 1) {
        this.formData.fixedMonthlyAmount = null;
      }
      if (this.amountType === 2) {
        this.formData.amount = null;
      }
    },
  },
};
</script>
