<template>
  <div class="user-not-registered">
    <div class="user-not-registered__content">
      <!-- Add x-large warning v-icon -->
      <v-icon large :color="$getColor('vuetifyColor1')" class="mb-3">warning</v-icon>
      <div class="user-not-registered__content__title">

        <h1>Sin acceso</h1>
      </div>

      <div class="user-not-registered__content__text">
        <p>
          No tienes acceso. Por favor, contacta con el administrador.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'user-not-registered',
};
</script>
<style scoped>
.user-not-registered {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height calculated for 100%  - nav height */
  height: calc(100vh - 120px);
  width: 100%;
  background-color: var(--colorBackgroundLight);
}

.user-not-registered__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
  padding: 20px;
  background-color: var(--colorBackground);
  border-radius: 5px;
  box-shadow: 0 0 10px var(--colorShadow);
}

.user-not-registered__content__title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.user-not-registered__content__title h1 {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--bodyColor);
  text-align: center;
}

.user-not-registered__content__text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.user-not-registered__content__text p {
  font-size: 1rem;
  font-weight: 400;
}
</style>
