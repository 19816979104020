<template>
  <div class="incidences-table">
    <!-- Add progress bar -->
    <v-progress-linear
      v-if="loading"
      :color="$getColor('vuetifyColor1')"
      class="linear-progress"
      indeterminate
    ></v-progress-linear>
    <!-- Add content -->
    <v-data-table
      :color="$getColor('vuetifyColor1')"
      :headers="isLogged ? loggedHeader : headers"
      :items="items"
      class="elevation-0"
      disable-sort
      :options.sync="options"
      :server-items-length="count"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-text': 'Elementos por página',

      }"
    >
      <template v-slot:[`item.unsolvedIncidence`]="{ item }">
        <!-- Icon for incidence -->
        <v-icon v-if="item.unsolvedIncidence" color="red accent-1"
          >mdi-alert-circle</v-icon
        >
        <v-icon v-else color="teal accent-4"
          >mdi-check-circle</v-icon
        >
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <span>
          {{ new Date(item.date).toISOString().split("T")[0] }}
        </span>
      </template>
        <template v-slot:[`item.name`]="{ item }" >
          <span class="font-weight-medium">
            {{ item.worker.name + ' ' + item.worker.lastName1 }}
          </span>
      </template>

      <template v-slot:[`item.managerComment`]="{ item }">
        <span class="font-weight-medium">
          {{ item.managerComment }}
        </span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="table-actions-container">
          <v-tooltip bottom v-if="item.unsolvedIncidence">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                class="mr-4"
                :dark="$getPrimaryButton('dark')"
                depressed
                :rounded="$getPrimaryButton('rounded')"
                :outlined="$getPrimaryButton('outlined')"
                color="teal accent-4"
                @click="solveIncidence(item.id)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left>task_alt</v-icon>
                Resolver
              </v-btn>
            </template>
            <span>Marcar como resuelta</span>
          </v-tooltip>
          <v-tooltip bottom v-if="!isWorker">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              icon
                small
                class="mr-4"
                :dark="$getPrimaryButton('dark')"
                depressed
                :rounded="$getPrimaryButton('rounded')"
                :outlined="$getPrimaryButton('outlined')"
                :color="$getPrimaryButton('color')"
                @click="goToWorkerPanel(item.worker.id)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-account-cog</v-icon>
              </v-btn>
            </template>
            <span>Panel de empleado</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import scheduleService from '@/services/schedule.service';
import loggedUserService from '@/services/logged-user.service';

export default {
  name: 'incidents-table',
  props: {
    workcenterId: {
      required: false,
    },
    workerId: {
      type: String,
      required: false,
    },
    isWorker: {
      type: Boolean,
      default: false,
      required: false,
    },
    isLogged: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      loaded: false,
      dataCountLoaded: 0,
      adminAvailable: false,
      items: [],
      showSolved: false,
      showUnsolved: true,

      headers: [
        { text: 'Estado', value: 'unsolvedIncidence' },
        { text: 'Trabajador/a', value: 'name', sortable: false },
        { text: 'Comentario', value: 'managerComment' },
        { text: 'Fecha de incidencia', value: 'date' },
        { text: 'Acciones', value: 'actions', sortable: false },

      ],
      loggedHeader: [
        { text: 'Estado', value: 'unsolvedIncidence' },
        { text: 'Comentario', value: 'managerComment' },
        { text: 'Fecha de incidencia', value: 'date' },
      ],
      tmpWorkerId: null,
      options: {},
      count: 0,

    };
  },
  watch: {
    options: {
      handler() {
        this.loadItems();
      },
      deep: true,
    },
  },
  methods: {
    updateShow(unsolved, solved) {
      if (!unsolved && !solved) {
        this.showSolved = solved;
        this.showUnsolved = unsolved;
        this.items = [];
      } else {
        this.showSolved = solved;
        this.showUnsolved = unsolved;
        this.loadItems();
      }
    },
    loadItems() {
      if (this.isLogged) {
        this.getLoggedUserIncidences();
        return;
      }
      if (this.workerId || this.isWorker) {
        if (this.workerId) {
          this.getWorkerItems(this.workerId);
        }
      } else {
        this.getItems();
      }
    },
    addNew() {
      this.$router.push({ name: 'CreateSapCode' });
    },
    getItems() {
      this.loading = true;
      scheduleService.getIncidencesByWorkCenterPaged(this.workcenterId, this.getRequestParams())
        .then((response) => {
          this.items = response.content;
          this.count = response.totalElements;
          this.loaded = true;
          this.loading = false;
          this.updateTotal();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.loaded = false;
        });
    },
    updateWorker(workerId) {
      this.tmpWorkerId = workerId;
      this.getWorkerItems(workerId);
    },
    refreshWorker() {
      if (this.tmpWorkerId) {
        this.getWorkerItems(this.tmpWorkerId);
      }
    },
    getWorkerItems(id) {
      this.loading = true;
      scheduleService.getIncidencesByWorker(id)
        .then((response) => {
          this.items = response.content;
          this.count = response.content.length;
          this.loaded = true;
          this.loading = false;
          this.updateTotal();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.loaded = false;
        });
    },
    getLoggedUserIncidences() {
      this.loading = true;
      loggedUserService.getIncidences()
        .then((response) => {
          this.items = response.content;
          this.count = response.content.length;
          this.loaded = true;
          this.loading = false;
          this.updateTotal();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.loaded = false;
        });
    },
    solveIncidence(id) {
      scheduleService.solveIncidence(id)
        .then(() => {
          this.loadItems();
          this.refreshWorker();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateTotal() {
      this.$emit('updateTotal', this.count);
    },
    goToWorkerPanel(id) {
      this.$router.push({ name: 'WorkerPanel', params: { id } });
    },
    getRequestParams() {
      const params = {};
      params.unsolvedIncidence = this.setUnsolvedIncidenceParam();
      // eslint-disable-next-line prefer-destructuring
      params.sort = this.options.sortBy[0];
      if (this.options.sortDesc[0]) {
        params.direction = 'desc';
      } else {
        params.direction = 'asc';
      }

      params.page = this.options.page - 1;
      params.size = this.options.itemsPerPage;
      return params;
    },

    setUnsolvedIncidenceParam() {
      if (!this.showUnsolved && !this.showSolved) {
        return null;
      } if (this.showUnsolved && this.showSolved) {
        return null;
      } if (this.showUnsolved && !this.showSolved) {
        return true;
      } if (!this.showUnsolved && this.showSolved) {
        return false;
      }
      return null;
    },
  },
};
</script>
<style lang="scss" scoped>
.table-actions-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
<style lang="scss">
.incidences-table {
  th[aria-label="Acciones: Not sorted. Activate to sort ascending."] {
   display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  th[aria-label="Acciones: Sorted ascending. Activate to sort descending."] {
   display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  th[aria-label="Acciones: Sorted descending. Activate to remove sorting."] {
   display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>
