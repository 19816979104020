<template>
    <v-dialog v-model="dialog" class="dialog-wrapper" width="650px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn depressed :dark="$getPrimaryButton('dark')"
          :rounded="$getPrimaryButton('rounded')" :outlined="$getPrimaryButton('outlined')"
          :color="$getPrimaryButton('color')" v-bind="attrs" v-on="on">
          <v-icon left>mdi-account-lock-open</v-icon>
          Asignar Administradores/as
        </v-btn>
      </template>
    <v-container fluid>
          <v-card outlined class="p-3" :elevation="$getCardOptions('elevation')">
            <v-card-title>
              <section-header
              first="Selecciona el usuario"
              second="y añadelo a la lista de administradores"
              />
            </v-card-title>
            <v-card-text>
              <v-form ref="form">
                <label class="input-field--label"> Seleccionar el usuario: </label>
                <v-select v-model="formData.email"  :items="users"  placeholder="Email de usuario"
                  :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="person">
                </v-select>
                <label v-if="userSelected" class="input-field--label"> Usuario selecionado: </label>
                <v-text-field v-if="userSelected" :value="userSelected.value" placeholder="Email del usuario" disabled
                  :color="$getColor('vuetifyColor1')" filled prepend-inner-icon="email">
                </v-text-field>
                <label v-if="userSelected" class="input-field--label"> Rol: </label>
                <v-text-field v-if="userSelected" :value="$getRoleTypeValues(userSelected.role).label" placeholder="Nombre" disabled
                  :color="$getColor('vuetifyColor1')" filled prepend-inner-icon="description">
                </v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="white--text" :rounded="$getPrimaryButton('rounded')" :disabled="$v.formData.$invalid"
                :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')" large @click="newRegister(userSelected.role)">
                Añadir usuario
              </v-btn>
            </v-card-actions>
          </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import SectionHeader from '@/components/Headers/SectionHeader.vue';

import workcenterService from '@/services/workcenter.service';

export default {
  name: 'add-admin',
  props: {
    workcenterId: {
      required: true,
    },
  },
  components: {
    SectionHeader,
  },
  data: () => ({
    mixins: [validationMixin],

    formData: {
      email: null,
      role: null,
    },
    dialog: false,
    users: [],

  }),

  validations() {
    const valObj = {
      formData: {
        email: { required },
      },

    };
    return valObj;
  },
  mounted() {
    this.getUsers();
  },
  computed: {
    userSelected() {
      // find user in users array
      return this.users.find((user) => user.value === this.formData.email);
    },
  },
  methods: {
    newRegister(role) {
      if (role === 'ROLE_ENCARGADO') {
        workcenterService.addManager(this.formData.email, this.workcenterId).then(() => {
          this.$emit('update');
          this.dialog = false;
        });
      } else if (role === 'ROLE_ADMIN') {
        workcenterService.addAdmin(this.formData.email, this.workcenterId).then(() => {
          this.$emit('update');
          this.dialog = false;
        });
      }
    },
    getUsers() {
      this.loading = true;
      workcenterService.getAllUsers().then((response) => {
        const allUsers = response.content.map((user) => ({
          value: user.username,
          text: `${user.username} - ${this.$getRoleTypeValues(user.role).label}`,
          role: user.role,
          workCenters: user.workCenters.map((workcenter) => workcenter.id),

        }));
        // only admins and encargados
        this.users = this.filterUsers(allUsers);
        this.loading = false;
      });
    },
    filterUsers(users) {
      // cast as integer projectId
      const projectId = parseInt(this.$store.state.projectId, 10);
      return users.filter((user) => !user.workCenters.includes(projectId) && (user.role === 'ROLE_ADMIN' || user.role === 'ROLE_ENCARGADO'));
    },

  },
};
</script>
