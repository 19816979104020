<template>
  <div>
    <v-dialog v-model="dialog" class="dialog-wrapper" width="650px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn :dark="$getPrimaryButton('dark')"
        depressed
          :rounded="$getPrimaryButton('rounded')" :outlined="$getPrimaryButton('outlined')"
          :color="$getPrimaryButton('color')" v-bind="attrs" v-on="on">
          <v-icon left>mdi-file-document-plus</v-icon>
          Añadir incidencia
        </v-btn>
      </template>
      <v-container fluid>
        <v-card outlined class="p-3" :elevation="$getCardOptions('elevation')">
          <v-card-title>
            <section-header first="Rellena la información" second="para crear la incidencia" />
          </v-card-title>
          <v-card-text>
            <v-form ref="form">
              <label class="input-field--label"> Añade un comentario a la incidencia: </label>
              <v-textarea v-model="comment" placeholder="Comentario"
                :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="description">
              </v-textarea>
              <label class="input-field--label"> Trabajador/a: </label>
              <v-text-field v-model="workerName" placeholder="Nombre" disabled
                :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="person">
              </v-text-field>
              <label class="input-field--label"> Fecha de la incidencia: </label>
              <v-dialog ref="dialogStart" v-model="menuDate" :return-value.sync="dateString" persistent
                width="450px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dateString" placeholder="FECHA FIN" disabled
                    prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                    :color="$getColor('vuetifyColor1')" outlined hide-details></v-text-field>
                </template>
                <v-date-picker v-model="dateString" scrollable :color="$getColor('vuetifyColor1')"
                  locale="es" first-day-of-week="1">
                  <v-spacer></v-spacer>
                  <v-btn text :color="$getColor('vuetifyColor1')" @click="menuDate = false">
                    Cancelar
                  </v-btn>
                  <v-btn text :color="$getColor('vuetifyColor1')"
                    @click="$refs.dialogStart.save(dateString)">
                    Seleccionar
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="white--text" :rounded="$getPrimaryButton('rounded')"
              :disabled="$v.comment.$invalid && $v.dayId.$invalid" :outlined="$getPrimaryButton('outlined')"
              :color="$getPrimaryButton('color')" large @click="newRegister" :loading="loading">
              Añadir incidencia
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import SectionHeader from '@/components/Headers/SectionHeader.vue';

import scheduleService from '@/services/schedule.service';

export default {
  name: 'create-incident',
  components: {
    SectionHeader,
  },
  data: () => ({
    mixins: [validationMixin],

    dialog: false,
    loading: false,

    menuDate: false,

    dateString: null,
    workerName: null,

    comment: null,
    dayId: null,

  }),

  validations() {
    const valObj = {
      comment: { required },
      dayId: { required },
    };
    return valObj;
  },
  methods: {
    updateData(workerName, obj) {
      this.workerName = workerName;
      this.dateString = obj.date;
      this.dayId = obj.id;
    },
    resetData() {
      this.comment = null;
      this.workerName = null;
      this.dateString = null;
      this.dayId = null;
    },
    newRegister() {
      this.loading = true;
      scheduleService.addManagerComment(this.dayId, this.comment)
        .then(() => {
          this.emitNewIncidence();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.closeDialog();
        });
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    // emit event to parent
    emitNewIncidence() {
      this.$emit('newIncidence');
    },
  },
};
</script>
