<template>
  <div>
    <EditCode ref="editCode" class="mb-3 ml-3" v-if="workcenterId" :workcenterId="workcenterId" />
    <!-- Add progress bar -->
    <v-progress-linear v-if="loading" :color="$getColor('vuetifyColor1')" class="linear-progress"
      indeterminate></v-progress-linear>
    <!-- Add content -->
    <v-data-table :color="$getColor('vuetifyColor1')" :headers="headers" :items="items"
      :items-per-page="10" class="elevation-1" calculate-widths
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-text': 'Elementos por página',

      }">
      <!-- template v-slot for group header -->
      <template v-slot:[`item.category`]="{ item }">
        <!-- v-chip for category -->
        <v-chip :color="getCategoryOptions(item.category).color" text-color="white">
          {{ getCategoryOptions(item.category).text }}
        </v-chip>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <span class="font-weight-bold">{{ item.name }}</span>
      </template>
      <template v-slot:[`item.extraHour`]="{ item }" >
        <v-icon  v-if="item.extraHour" :color="$getColor('vuetifyColor1')">numbers</v-icon>
      </template>
      <template v-slot:[`item.admin`]="{ item }">
        <v-icon v-if="item.admin" :color="$getColor('vuetifyColor2')">admin_panel_settings</v-icon>
      </template>
      <template v-slot:[`item.autoVariable`]="{ item }">
        <v-icon v-if="item.autoVariable" :color="$getColor('vuetifyColor2')">autorenew</v-icon>
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        <span v-if="item.amount">{{ item.amount }} €</span>
      </template>
      <template v-slot:[`item.fixedMonthlyAmount`]="{ item }">
        <span v-if="item.fixedMonthlyAmount">{{ item.fixedMonthlyAmount }} €</span>
      </template>
      <template v-slot:[`item.weekend`]="{ item }">
        <span class="caption font-italic">{{ getTextFromWeekendOptions(item.weekend) }}</span>
      </template>
      <template v-slot:[`item.monthly`]="{ item }">
        <span >{{ getTextFromMonthlyOptions(item.monthly) }}</span>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <span class="caption font-italic">{{ getTextFromTypeOptions(item.type) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                depressed
                small
                :dark="$getPrimaryButton('dark')"
                :rounded="$getPrimaryButton('rounded')"
                :outlined="$getPrimaryButton('outlined')"
                :color="$getPrimaryButton('color')"
                @click="editCode(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>edit</v-icon>
              </v-btn>
            </template>
            <span>Editar código N2</span>
          </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import EditCode from '@/views/_FATDA/components/EditCode.vue';
// eslint-disable-next-line import/no-cycle
import workcenterService from '@/services/workcenter.service';

export default {
  name: 'codes-view',
  props: {
    workcenterId: {
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      loaded: false,
      dataCountLoaded: 0,
      adminAvailable: false,
      items: [],
      count: 0,
      headers: [
        { text: 'Codigo', value: 'code', groupable: false },
        { text: 'Admin', value: 'admin', width: '90px' },
        { text: 'Auto', value: 'autoVariable', width: '90px' },
        { text: 'Unidades', value: 'extraHour', width: '100px' },
        { text: 'Nombre', value: 'name', groupable: false },
        { text: 'Tipo', value: 'category' },
        { text: 'Importe', value: 'amount', groupable: false },
        { text: 'Importe mensual', value: 'fixedMonthlyAmount', groupable: false },
        { text: 'Periodo', value: 'monthly', groupable: false },
        { text: 'Festivo', value: 'weekend', groupable: false },
        { text: 'Turno', value: 'type', groupable: false },
        { text: 'Acciones', value: 'actions' },
      ],
      categoryOptions: [
        { text: 'Variable', value: 'variables', color: '#f4796b' },
        { text: 'Absentismo', value: 'absences', color: '#00BFA5' },
      ],

      typeOptions: [
        { text: 'Turno de mañana/tarde', value: 'morning/evening' },
        { text: 'Turno de Noche', value: 'night' },
        { text: 'Todos', value: 'all' },
      ],

      weekendOptions: [
        { text: 'Festivo', value: '1' },
        { text: 'Laborable', value: '0' },
        { text: 'Cualquiera', value: 'all' },
      ],
      monthlyOptions: [
        { text: 'Mensual', value: true },
        { text: 'Quincenal', value: false },
      ],
    };
  },
  components: {
    EditCode,
  },
  mounted() {
    this.adminAvailable = this.$store.getters['auth/hasRole'](['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']);
    if (!this.adminAvailable) {
      this.$router.push('/acceso-denegado');
    } else {
      this.tabMain = 0;
      this.getItems();
    }
  },
  methods: {
    addNew() {
      this.$router.push({ name: 'CreateSapCode' });
    },
    getItems() {
      this.loading = true;
      workcenterService.getN2Codes(this.workcenterId)
        .then((response) => {
          this.items = response;
          this.count = response.length;
          this.loaded = true;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.loaded = false;
        });
    },
    getTextFromWeekendOptions(value) {
      const option = this.weekendOptions.find((item) => item.value === value);
      return option ? option.text : '';
    },
    getTextFromTypeOptions(value) {
      const option = this.typeOptions.find((item) => item.value === value);
      return option ? option.text : '';
    },
    getCategoryOptions(value) {
      const option = this.categoryOptions.find((item) => item.value === value);
      option.color = option.color || 'rgb(255, 64, 129)';
      option.text = option.text || ' - ';
      return option;
    },
    getTextFromMonthlyOptions(value) {
      const option = this.monthlyOptions.find((item) => item.value === value);
      return option ? option.text : '';
    },
    editCode(code) {
      this.$refs.editCode.updateFormData(code);
    },
  },
};
</script>
