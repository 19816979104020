/* eslint-disable class-methods-use-this */
import axios from 'axios';
import authHeader from '@/services/auth-header';

const API_URL = `${process.env.VUE_APP_API_URL}`;

class UserService {
  disableUser(userId) {
    return axios
      .post(`${API_URL}disable-user/${userId}`, null, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  userByUsername(username) {
    return axios
      .get(`${API_URL}users/${username}`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }
}

export default new UserService();
