<template>
  <v-dialog v-model="dialog" class="dialog-wrapper" width="650px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :dark="$getPrimaryButton('dark')" depressed :rounded="$getPrimaryButton('rounded')"
        :outlined="false" color="success" v-bind="attrs" v-on="on" @click="openDialog">
        <v-icon left>mdi-clock-edit</v-icon>Fichaje manual
      </v-btn>
    </template>
    <v-container fluid>
      <v-card outlined class="p-3" :elevation="$getCardOptions('elevation')">
        <v-card-title class="p-0">
          <v-spacer></v-spacer>
          <v-btn class="ml-auto" :rounded="$getPrimaryButton('rounded')"
            :color="$getPrimaryButton('color2')" @click="closeDialog" :disabled="false"
            :loading="loading" icon large>
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-title class="pt-0">
          <section-header :first="ComputedTexts.first" :second="ComputedTexts.second" />
        </v-card-title>
        <v-card-text v-if="!loading">
          <v-form ref="form">
            <div v-if="step === 4" class="py-3">
              <label class="input-field--label pt-3"> Tipo de fichaje: </label>
              <v-text-field v-model="ComputedClockEntryType" readonly focus hide-details
                  :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="mdi-clock-alert-outline">{{ ClockEntryType }}
              </v-text-field>
              <label class="input-field--label pt-3"> Fecha: </label>
              <v-text-field v-model="ClockEntryDate" readonly focus hide-details
                  :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="mdi-calendar">{{ ClockEntryType }}
              </v-text-field>
              <label class="input-field--label pt-3"> Hora: </label>
              <v-text-field v-model="ClockEntryTime" readonly focus hide-details
                  :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="mdi-clock">{{ ClockEntryType }}
              </v-text-field>
            </div>
            <v-btn v-if="step === 1" class="p-2 white--text" :rounded="$getPrimaryButton('rounded')"
              :outlined="$getPrimaryButton('outlined')" color="success" @click="setEntrada"
              :disabled="false" :loading="loading" block x-large>
              <v-icon class="pr-3" left>mdi-clock-plus</v-icon>
              Entrada
            </v-btn>
            <v-btn right v-if="step === 1" class="p-2 mt-4 white--text"
              :rounded="$getPrimaryButton('rounded')" :outlined="$getPrimaryButton('outlined')"
              :color="$getPrimaryButton('color')" @click="setSalida" :disabled="false"
              :loading="loading" block x-large>
              <v-icon class="pr-3" left>mdi-location-exit</v-icon>
              Salida
            </v-btn>
            <v-date-picker v-model="ClockEntryDate" locale="es" full-width first-day-of-week="1"
              :max="DateTimeNow" v-if="step === 2">
            </v-date-picker>
            <v-time-picker v-model="ClockEntryTime" format="24hr" locale="es" full-width
              v-if="step === 3" large></v-time-picker>
          </v-form>
        </v-card-text>
        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <v-container>
            <v-row>
              <v-spacer></v-spacer>
              <v-btn v-if="ClockEntryDate && step === 2 || ClockEntryTime && step === 3"
                class="pr-2 white--text" :rounded="$getPrimaryButton('rounded')"
                :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')"
                @click="nextStep" :disabled="false" :loading="loading" width="48%" large block>
                Siguiente
              </v-btn>
              <v-btn v-if="step === 4" class="pr-2 white--text"
                :rounded="$getPrimaryButton('rounded')" :outlined="$getPrimaryButton('outlined')"
                color="success" @click="addClockEntryManual" :disabled="false" :loading="loading" width="48%"
                large block>
                <v-icon class="pr-3" left>mdi-upload</v-icon>
                Añadir
              </v-btn>
              <!--
                <v-btn class="ml-auto" :rounded="$getPrimaryButton('rounded')"
                :color="$getPrimaryButton('color2')" @click="closeDialog" :disabled="false"
                :loading="loading" width="48%" large>
                Atrás
              </v-btn>
               -->
            </v-row>
          </v-container>

        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import scheduleService from '@/services/schedule.service';

import SectionHeader from '@/components/Headers/SectionHeader.vue';
// import moment from 'moment-timezone';

export default {
  name: 'clock-entry-register',
  data: () => ({

    dialog: false,
    loading: false,
    currentDateTime: null,
    DateTimeNow: new Date().toISOString(),
    ClockEntryDate: null,
    ClockEntryType: null,
    ClockEntryTime: null,
    worker: null,
    step: 1,

  }),
  props: {
    isAdmin: {
      type: Boolean,
      required: true,
      default: false,
    },
    currentValue: {
      required: false,
    },
  },
  components: {
    SectionHeader,
  },
  computed: {
    ComputedClockEntryType() {
      if (this.ClockEntryType === 0) {
        return 'ENTRADA';
      } if (this.ClockEntryType === 1) {
        return 'SALIDA';
      }
      return null;
    },
    ComputedDateTimeNow() {
      return this.DateTimeNow.toLocaleString();
    },
    ComputedUnixTime() {
      if (this.ClockEntryDate && this.ClockEntryTime) {
        // Combinar la fecha y la hora en un string compatible con Date
        const dateTimeString = `${this.ClockEntryDate}T${this.ClockEntryTime}:00`;
        // Crear un objeto Date con la fecha y hora combinada
        const dateObj = new Date(dateTimeString);

        // Asegurarse de que el objeto Date está configurado para la zona horaria de España
        // (España está en la zona horaria Central European Time, CET, o Central European Summer Time, CEST)
        // Podemos configurar el objeto Date para la zona horaria local
        dateObj.toLocaleString('es-ES', { timeZone: 'Europe/Madrid' });

        // Obtener el valor Unix Time (en segundos)
        const unixTimestampSeconds = Math.floor(dateObj.getTime() / 1000);

        // Mostrar el valor Unix Time
        return unixTimestampSeconds;
      }
      return null;
    },
    ComputedTexts() {
      if (this.loading) {
        return { first: '', second: 'Cargando...' };
      }
      if (this.step === 1) {
        return { first: 'Selecciona', second: 'el tipo de fichaje' };
      }
      if (this.step === 2) {
        return { first: 'Selecciona la fecha', second: 'del día del fichaje' };
      }
      if (this.step === 3) {
        return { first: 'Selecciona la hora', second: 'del fichaje' };
      }
      if (this.step === 4) {
        return { first: 'Comprueba los datos', second: 'y haz click en `Añadir`' };
      }
      return { first: 'Pulsa atras', second: 'para volver...' };
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.updateCurrentDateTime();
      }
    },
  },
  mounted() {
  },
  methods: {
    updateWorker(worker) {
      this.worker = worker;
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.ClockEntryDate = null;
      this.ClockEntryType = null;
      this.ClockEntryTime = null;
      this.step = 1;
      this.dialog = false;
    },
    setEntrada() {
      this.ClockEntryType = 0;
      this.nextStep();
    },
    setSalida() {
      this.ClockEntryType = 1;
      this.nextStep();
    },
    nextStep() {
      this.step += 1;
    },
    updateCurrentDateTime() {
      const now = new Date();
      // Formatear la fecha y hora según tus necesidades
      const formattedDateTime = this.formatDateTime(now);
      this.currentDateTime = formattedDateTime;
    },
    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      const options = {
        year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric',
      };
      return new Intl.DateTimeFormat('es-ES', options).format(date);
    },
    addClockEntryManual() {
      this.loading = true;
      scheduleService.addClockEntryAdmin(this.worker.id, this.ComputedUnixTime, this.ClockEntryType)
        .then(() => {
          this.refreshClockEntries();
          this.closeDialog();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    refreshClockEntries() {
      this.$emit('refreshClockEntries');
    },
  },
};
</script>
<style scoped>
.input-clock--label {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: calc(var(--base) * 3);
  margin-left: auto;
  margin-right: auto;
  color: #f4796b;
  font-weight: 300;
}

.box-info {
  display: flex;
  margin: auto;
}
</style>
