/* eslint-disable class-methods-use-this */
import axios from 'axios';
import authHeader from '@/services/auth-header';

const API_URL = `${process.env.VUE_APP_API_URL}`;

class WorkCenterService {
  getWorker(id) {
    return axios

      .get(`${API_URL}worker/${id}`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  getWorkers(params) {
    return axios
      .get(`${API_URL}worker`, { params, headers: authHeader() })
      .then((response) => response.data);
  }

  getAllWorkcenters() {
    return axios
      .get(`${API_URL}work-center/all`, { params: { size: 999 }, headers: authHeader() })
      .then((response) => response.data);
  }

  getWorkcenter(id) {
    return axios
      .get(`${API_URL}work-center/${id}`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  createWorkcenter(workcenter) {
    return axios
      .post(`${API_URL}work-center`, workcenter, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  createWorker(worker, workcenterId) {
    return axios.post(
      `${process.env.VUE_APP_API_URL}work-center/${workcenterId}/add-worker`,
      worker,
      {
        headers: authHeader(),
      },
    )
      .then((response) => response.data);
  }

  addManager(manager, workcenterId) {
    const headers = authHeader();
    headers['Content-Type'] = 'text/plain';
    return axios.post(
      `${process.env.VUE_APP_API_URL}work-center/${workcenterId}/add-manager`,
      manager,
      {
        headers,
      },
    )
      .then((response) => response.data);
  }

  addAdmin(admin, workcenterId) {
    const headers = authHeader();
    headers['Content-Type'] = 'text/plain';
    return axios.post(
      `${process.env.VUE_APP_API_URL}work-center/${workcenterId}/add-admin`,
      admin,
      {
        headers,
      },
    )
      .then((response) => response.data);
  }

  removeWorker(workerEmail, workcenterId) {
    const headers = authHeader();
    headers['Content-Type'] = 'text/plain';
    return axios.post(`${process.env.VUE_APP_API_URL}work-center/${workcenterId}/remove-worker`, workerEmail, {
      headers,
    })
      .then((response) => response.data);
  }

  removeManager(managerEmail, workcenterId) {
    const headers = authHeader();
    headers['Content-Type'] = 'text/plain';
    return axios.post(`${process.env.VUE_APP_API_URL}work-center/${workcenterId}/remove-manager`, managerEmail, {
      headers,
    })
      .then((response) => response.data);
  }

  removeAdmin(adminEmail, workcenterId) {
    const headers = authHeader();
    headers['Content-Type'] = 'text/plain';
    return axios.post(`${process.env.VUE_APP_API_URL}work-center/${workcenterId}/remove-admin`, adminEmail, {
      headers,
    })
      .then((response) => response.data);
  }

  addN2Code(workcenterId, n2Code) {
    return axios.post(
      `${process.env.VUE_APP_API_URL}work-center/${workcenterId}/add-n2code`,
      n2Code,
      {
        headers: authHeader(),
      },
    )
      .then((response) => response.data);
  }

  checkN2Code(workcenterId, n2CodeId) {
    return axios.get(
      `${process.env.VUE_APP_API_URL}work-center/${workcenterId}/n2codes/${n2CodeId}/check-assigned`,
      {
        headers: authHeader(),
      },
    )
      .then((response) => response.data);
  }

  editN2Code(workcenterId, n2Code) {
    return axios.post(
      `${process.env.VUE_APP_API_URL}work-center/${workcenterId}/edit-n2code`,
      n2Code,
      {
        headers: authHeader(),
      },
    )
      .then((response) => response.data);
  }

  addN2CodeToWorker(workerId, n2CodeId) {
    return axios.post(
      `${process.env.VUE_APP_API_URL}worker/${workerId}/add-n2code/${n2CodeId}`,
      {},
      {
        headers: authHeader(),
      },
    )
      .then((response) => response.data);
  }

  deleteN2CodeFromWorker(workerId, n2CodeId) {
    return axios.delete(
      `${process.env.VUE_APP_API_URL}worker/${workerId}/delete-n2code/${n2CodeId}`,
      {
        headers: authHeader(),
      },
    )
      .then((response) => response.data);
  }

  getN2Codes(workcenterId) {
    return axios
      .get(`${API_URL}work-center/${workcenterId}/n2codes`, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  getUsersByWorkcenter(id) {
    const params = { workCenterId: id, size: 9999 };
    return axios
      .get(`${API_URL}users`, { params, headers: authHeader() })
      .then((response) => response.data);
  }

  getAllUsers() {
    const params = { size: 9999 };
    return axios
      .get(`${process.env.VUE_APP_API_URL}users/`, {
        headers: authHeader(),
        params,
      })
      .then((response) => response.data);
  }
}

export default new WorkCenterService();
