<template>
  <div>
    <v-data-table :headers="headers" :items="items" :options.sync="options" :server-items-length="count"
      class="elevation-1" calculate-widths :hide-default-footer="false" :footer-props="{
        showFirstLastPage: true,
        'items-per-page-text': 'Elementos por página',

      }" :color="$getColor('vuetifyColor1')" outlined flat elevation="0">
      <template v-slot:[`item.creationDate`]="{ item }">
        <span>
          {{ new Date(item.creationDate).toISOString().split('T')[0] }} {{
            new
                    Date(item.creationDate).toISOString().split('T')[1].split('.')[0]
          }}
        </span>
      </template>

    </v-data-table>
  </div>
</template>
<script>

import axios from '@/services/http-common';
import authHeader from '@/services/auth-header';

export default {
  name: 'log-standard-table',
  props: {
    username: {
      type: String,
      default: null,
    },
    role: {
      type: Number,
      default: null,
    },
    action: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      items: [],
      currentIndex: -1,
      searchTitle: '',
      page: 1,
      count: 0,
      pageSize: 10,
      pageSizes: [5, 10, 20],

      currentSort: 'creationDate',
      currentSortOrder: 'desc',
      loading: true,

      options: {},
      headers: [

        { text: 'Id', value: 'id' },
        { text: 'Usuario', value: 'userModel.username' },
        { text: 'Acción', value: 'action' },
        { text: 'Detalles', value: 'description' },
        { text: 'Fecha', value: 'creationDate' },

      ],
    };
  },
  watch: {
    username() {
      this.getItems();
    },
    role() {
      this.getItems();
    },
    action() {
      this.getItems();
    },
    options: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },
  mounted() {
    this.options.sortDesc[0] = true;
  },
  methods: {
    getRequestParams() {
      const params = {};
      params.username = this.username;
      params.role = this.role;
      params.action = this.action;

      if (this.options.sortBy) {
        // eslint-disable-next-line prefer-destructuring
        params.sort = this.options.sortBy[0];
      }
      if (this.options.sortDesc && this.options.sortDesc[0]) {
        params.direction = 'desc';
      } else {
        params.direction = 'asc';
      }
      params.page = this.options.page - 1;
      params.size = this.options.itemsPerPage;

      return params;
    },

    getItems() {
      this.loading = true;
      axios
        .get(`${process.env.VUE_APP_API_URL}log`, { headers: authHeader(), params: this.getRequestParams() })
        .then((response) => {
          this.items = response.data.content;
          this.count = response.data.totalElements;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
