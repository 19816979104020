<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
<template>
  <button
    :class="cardClass"
    class="code-card"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <div class="code-card--info">
      <div class="code-card--title">{{ name }}</div>
      <div class="code-card--description">{{ description }}</div>
    </div>
    <div class="code-card--icon" v-if="selected && !isHovered">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.66669 8L6.66669 12L13.3334 4"
          stroke="#FF0000"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div class="code-card--icon" v-if="selected && isHovered">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
          fill="#FF0000"
        />
      </svg>
    </div>
    <div class="code-card--icon" v-if="!selected && isHovered">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.9025 12.997V11.003H12.997V3.09755H11.003V11.003H3.09753V12.997H11.003V20.9025H12.997V12.997H20.9025Z"
          fill="#ff0000"
        />
      </svg>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    disableMouse: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'Nombre',
    },
    description: {
      type: String,
      default: null,
    },
    img: {
      type: String,
      default: '',
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    color: {
      type: String,
      default: 'customRed',
    },
    size: {
      type: String,
      default: 'small',
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    cardClass() {
      if (this.disableMouse) {
        return this.selected ? `code-card selected ${this.size} disable-mouse` : `code-card ${this.size} disable-mouse`;
      }
      return this.selected ? `code-card selected ${this.size}` : `code-card ${this.size}`;
    },
  },

};
</script>

<style lang="scss" scoped>
.code-card {
  width: 100%;
  max-width: calc(var(--base) * 17);
  min-width: calc(var(--base) * 17);
  height: 100%;
  max-height: calc(var(--base) * 20);
  min-height: calc(var(--base) * 12);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: calc(var(--base) * 1);
  border: solid 2px rgba(var(--bodyColor), 0.1);
  padding: calc(var(--base) * 1.3);
  transition: all ease-out 0.3s;
  background-color: rgb(var(--bodyBg));
  &:hover {
    transform: scale(1.05);
  }
  &.disable-mouse{
    pointer-events:none;
    border:none;
    opacity: 0.7;
  }
  .code-card--info {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    height: 100%;
    justify-content: space-between;
    letter-spacing: -0.03em;

    .code-card--title {
      font-size: calc(var(--base) * 1.65);
      font-weight: 400;
      line-height: 1;
      margin-bottom: calc(var(--base) * 0.5);
      text-align: left;
    }
    .code-card--description {
      font-size: calc(var(--base) * 1.75);
      font-weight: 300;
      line-height: calc(var(--base) * 2);
      opacity: 0.65;
    }
  }

  //Sizes
  &.fullwidth {
    width: 100%;
    max-width: 100%;
    align-items: flex-start;
    .code-card--info {
      align-items: flex-start;
    }
  }
  &.large {
    width: 100%;
    max-width: calc(var(--base) * 17);
    align-items: flex-start;
    .code-card--info {
      align-items: flex-start;
    }
  }

  //Colors
  &.selected {
    background-color: rgba(var(--bodyColor), 1);
    border: solid 2px rgba(var(--bodyBg), 0.1);
    color: rgba(var(--bodyBg), 1);
    &.customGreen {
      background-color: var(--colorGreen);
      border: solid 2px rgba(var(--bodyColor), 0.1);
      color: rgba(var(--bodyColor), 1);
    }
    &.customPurple {
      background-color: var(--colorPurple);
      border: solid 2px rgba(var(--bodyColor), 0.1);
      color: rgba(var(--bodyColor), 1);
    }
    &.customPink {
      background-color: var(--colorPink);
      border: solid 2px rgba(var(--bodyColor), 0.1);
      color: rgba(var(--bodyColor), 1);
    }
    &.customRed {
      background-color: var(--colorRed);
      border: solid 2px rgba(var(--bodyColor), 0.1);
      color: rgba(var(--bodyColor), 1);
    }
  }
}
.code-card--icon {
  //position: absolute;
  //right: calc(var(--base) * 1);
  //bottom: calc(var(--base) * 1.4);
  position: relative;
  left: calc(var(--base) * 12);
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(var(--base) * 2);
  height: calc(var(--base) * 2);
  svg {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
</style>
