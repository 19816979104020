import { render, staticRenderFns } from "./MiddleLogin.vue?vue&type=template&id=3c64a85d&scoped=true"
import script from "./MiddleLogin.vue?vue&type=script&lang=js"
export * from "./MiddleLogin.vue?vue&type=script&lang=js"
import style0 from "./MiddleLogin.vue?vue&type=style&index=0&id=3c64a85d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c64a85d",
  null
  
)

export default component.exports