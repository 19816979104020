import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#F4796B',
        secondary: '#b0bec5',
        success: '#00BFA5',
        accent: '#F40000',
        error: '#b71c1c',
        black: '#090909',
        text: '#646466',
      },
      dark: {
        primary: '#F4796B',
        secondary: '#b0bec5',
        success: '#00BFA5',
        accent: '#F40000',
        error: '#b71c1c',
        black: '#090909',
        text: '#646466',
      },
    },
  },
};

export default new Vuetify(opts);
