import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from '@/store/auth.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    project: sessionStorage.getItem('projectName'),
    projectIcon: sessionStorage.getItem('projectIcon'),
    projectId: sessionStorage.getItem('projectId'),
    uniqueEvent: sessionStorage.getItem('uniqueEventId'),
    availableSchedule: sessionStorage.getItem('availableSchedule'),
    availableClockEntries: sessionStorage.getItem('availableClockEntries'),
  },
  mutations: {
    setProject(state, newProject) {
      state.project = newProject.text;
      state.projectIcon = newProject.icon;
      state.projectId = newProject.value;
      state.availableSchedule = newProject.availableSchedule;
      state.availableClockEntries = newProject.availableClockEntries;
      sessionStorage.setItem('projectName', newProject.text);
      sessionStorage.setItem('projectIcon', newProject.icon);
      sessionStorage.setItem('projectId', newProject.value);
      sessionStorage.setItem('availableClockEntries', newProject.availableClockEntries);
      sessionStorage.setItem('availableSchedule', newProject.availableSchedule);
    },
    clearProject(state) {
      state.project = null;
      state.projectIcon = null;
      state.projectId = null;
      state.availableClockEntries = null;
      state.availableSchedule = null;
      sessionStorage.removeItem('projectName');
      sessionStorage.removeItem('projectIcon');
      sessionStorage.removeItem('projectId');
      sessionStorage.removeItem('availableClockEntries');
      sessionStorage.removeItem('availableSchedule');
    },
    setUniqueEventId(state, newUniqueEvent) {
      state.uniqueEvent = newUniqueEvent;
      sessionStorage.setItem('uniqueEventId', newUniqueEvent);
    },
  },
  actions: {
  },
  modules: {
    auth,
  },
});
