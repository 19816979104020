import Vue from 'vue';

// Vue Layers
import VueLayers from 'vuelayers';
import 'vuelayers/dist/vuelayers.css';

// Vuelidate
import Vuelidate from 'vuelidate';

// Vuetify
import vuetify from '@/plugins/vuetify';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import props from './utils/config';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';

import './assets/css/vars.scss';
import './assets/css/style.scss';
import './assets/css/global.scss';
import './assets/css/global_components.scss';

Vue.use(Vuelidate);
Vue.use(VueLayers);

Vue.config.productionTip = false;

Vue.use(props);

new Vue({
  vuetify,
  validations: {},
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
vuetify.preset.theme.themes.light.primary = '#f40000';
