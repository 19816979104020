<template>
  <div>
    <canvas id="activity-chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'activity-chart',
  props: {
    color: {
      type: String,
      default: '#4caf50',
    },
  },

  data() {
    return {
      chartObj: null,
      userActivityData: {
        totalActivity: [],
      },
      userActivityChartData: {
        type: 'line',
        data: {
          labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto',
            'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          datasets: [
            {
              label: 'User activity',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              backgroundColor: this.color,
              borderColor: this.color,
              borderWidth: 3,
            },
          ],
        },
        options: {
          responsive: true,
          lineTension: 0.1,
          scales: {
          },
        },
      },
    };
  },

  mounted() {
    const ctx = document.getElementById('activity-chart');
    this.userActivityChartData.data.datasets[0].data = this
      .userActivityData.totalActivity;

    // eslint-disable-next-line no-new
    this.charObj = new Chart(ctx, this.userActivityChartData);
  },

  methods: {
    updateActivityData(val) {
      this.userActivityData.totalActivity = val.totalActivity;

      const currentMonth = new Date().getMonth() + 1;

      this.userActivityChartData.data.datasets[0].data = this
        .moveArray(this.userActivityData.totalActivity, currentMonth);

      this.userActivityChartData.data.labels = this
        .moveArray(this.userActivityChartData.data.labels, currentMonth);

      this.charObj.update();
    },
    moveArray(arr, offset) {
      return arr.slice(offset).concat(arr.slice(0, offset));
    },
  },
};
</script>
