<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="view-ct-dashboard">
    <div class="dashboard-toolbar mdtec-card">
      <div class="mdtec-card--header__title" v-if="this.$vuetify.breakpoint.width < 1024">
        Resúmen
      </div>
      <WorkcenterSelectorNav />
      <div class="dashboard-toolbar--insights">
        <div class="dashboard-toolbar--field-group insight-item">
          <div class="dashboard-toolbar--field-group__label">
            <v-icon small>mdi-account-group</v-icon>
            Personal:
          </div>
          <div class="dashboard-toolbar--field-group__value">
            <div class="dashboard-toolbar--field-group__value__text">
              <span class="number">{{ totalWorkers }}</span>
              <span class="unit">empleados/as</span>
            </div>
            <!-- <v-icon small>mdi-chevron-down</v-icon> -->
          </div>
        </div>
        <div class="dashboard-toolbar--field-group insight-item">
          <div class="dashboard-toolbar--field-group__label">
            <v-icon small color="primary">mdi-alert-circle</v-icon>
            Incidencias sin resolver:
          </div>
          <div class="dashboard-toolbar--field-group__value text-red">
            <div class="dashboard-toolbar--field-group__value__text">
              <span class="number">{{ totalUnsolved }}</span>
              <span class="unit">incidencias</span>
            </div>
            <v-icon size="32" color="primary">mdi-alert-circle</v-icon>
          </div>
        </div>
        <div class="dashboard-toolbar--field-group insight-item">
          <div class="dashboard-toolbar--field-group__label">
            <v-icon small color="success">mdi-check-circle</v-icon>
            Incidencias resueltas:
          </div>
          <div class="dashboard-toolbar--field-group__value text-green">
            <div class="dashboard-toolbar--field-group__value__text">
              <span class="number">{{ totalSolved }}</span>
              <span class="unit">incidencias</span>
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard-toolbar--actions">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="primary" v-bind="attrs" v-on="on" large @click="goToQuadrants()" depressed>
              <v-icon dark>mdi-calendar</v-icon>
            </v-btn>
          </template>
          <span>Ver cuadrantes</span>
        </v-tooltip>
        <!--
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="primary" v-bind="attrs" v-on="on" large @click="goToCenterConfig()">
              <v-icon dark>mdi-office-building-cog</v-icon>
            </v-btn>
          </template>
          <span>Configurar centro de trabajo</span>
        </v-tooltip>
        -->

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="primary" v-bind="attrs" v-on="on" large @click="goToN2()">
              <v-icon dark>functions</v-icon>
            </v-btn>
          </template>
          <span>Generar N2</span>
        </v-tooltip>
      </div>
    </div>
    <div class="dashboard-panels">
      <div class="dashboard-panels--tabs mdtec-card no-padding">
        <v-tabs v-model="tab" centered grow background-color="white" color="primary" light persistent>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-1">
            <v-icon class="mr-3">mdi-alert-circle</v-icon>
            Incidencias
          </v-tab>
          <v-tab href="#tab-2">
            <v-icon class="mr-3">mdi-account-group</v-icon>
            Personal
          </v-tab>
          <v-tab href="#tab-3" v-if="this.$store.getters['auth/hasRole'](['ROLE_SUPER_ADMIN'])">
            <v-icon class="mr-3">mdi-account-lock-open</v-icon>
            Adminstradores/as
          </v-tab>

          <v-tab href="#tab-4" v-if="this.$store.getters['auth/hasRole'](['ROLE_SUPER_ADMIN','ROLE_ADMIN' ])">
            <v-icon class="mr-3">mdi-text-box-multiple</v-icon>
            Codigos SAP
          </v-tab>
        </v-tabs>
      </div>
      <div class="dashboard-panels--tabs-panel">
        <v-tabs-items v-model="tab" background="transparent">
          <v-tab-item value="tab-1" eager>
            <div class="mdtec-card incidences-panel">
              <div class="mdtec-card--header">
                <div class="mdtec-card--header__title">
                  Tabla de incidencias
                </div>
                <div class="mdtec-card--header__actions">
                  <div class="filter-label mr-3">
                    Mostrar incidencias:
                  </div>
                  <v-checkbox v-model="checkboxNonResolved" label="Sin resolver" color="primary" hide-details
                    class="mr-2" @change="updateShowIncidences"></v-checkbox>
                  <v-checkbox v-model="checkboxResolved" label="Resueltas" color="teal accent-4" hide-details
                    @change="updateShowIncidences"></v-checkbox>
                </div>
              </div>
              <div class="mdtec-card--content">
                <Incidences ref="incidences" v-if="workcenterId" :workcenterId="workcenterId"
                  @updateTotal="updateTotalIncidences" />
              </div>
            </div>
          </v-tab-item>
          <v-tab-item value="tab-2" eager>
            <div class="mdtec-card personal-panel">
              <div class="mdtec-card--header">
                <div class="mdtec-card--header__title">Tabla de personal</div>
                <div class="mdtec-card--header__actions">
                  <v-btn depressed outlined text @click="goToWorkers()">Filtrar</v-btn>
                  <CreateWorker v-if="this.$store.getters['auth/hasRole'](['ROLE_SUPER_ADMIN','ROLE_ADMIN' ]) && workcenterId" :workcenterId="workcenterId" />
                </div>
              </div>
              <div class="mdtec-card--content">
                <WorkersSimpleTable @updateTotal="updateTotal" />
              </div>
            </div>
          </v-tab-item>
          <v-tab-item value="tab-3" v-if="this.$store.getters['auth/hasRole'](['ROLE_SUPER_ADMIN'])">
            <div class="mdtec-card personal-panel">
              <div class="mdtec-card--header">
                <div class="mdtec-card--header__title">
                  Tabla de Administradores
                </div>
                <div class="mdtec-card--header__actions">
                  <AddAdmin ref="addAdmin" v-if="workcenterId" class="" :workcenterId="workcenterId" @update="updateAdminsTable" />
                </div>
              </div>
              <div class="mdtec-card--content">
                <Admins ref="adminsTable" v-if="workcenterId" :workcenterId="workcenterId" @update="updateAddAdmin" />
              </div>
            </div>
          </v-tab-item>
          <v-tab-item value="tab-4" v-if="this.$store.getters['auth/hasRole'](['ROLE_SUPER_ADMIN','ROLE_ADMIN' ])">
            <div class="mdtec-card sap-panel">
              <div class="mdtec-card--header">
                <div class="mdtec-card--header__title">
                  Tabla de códigos SAP
                </div>
                <div class="mdtec-card--header__actions">
                  <CreateCode v-if="workcenterId" class="" :workcenterId="workcenterId"  />
                </div>
              </div>
              <div class="mdtec-card--content">
                <Codes v-if="workcenterId" :workcenterId="workcenterId" />
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<script>
import workcenterService from '@/services/workcenter.service';
import scheduleService from '@/services/schedule.service';

import Codes from '@/views/_FATDA/components/Codes.vue';
import Incidences from '@/views/_FATDA/components/Incidences.vue';
import CreateCode from '@/views/_FATDA/components/CreateCode.vue';
import CreateWorker from '@/views/_FATDA/components/CreateWorker.vue';
import AddAdmin from '@/views/_FATDA/components/AddAdmin.vue';
import WorkersSimpleTable from '@/views/_FATDA/components/WorkersSimpleTable.vue';
import Admins from '@/views/_FATDA/components/Admins.vue';
import WorkcenterSelectorNav from '@/views/_FATDA/components/WorkcenterSelectorNav.vue';

export default {
  name: 'HomePanel',
  components: {
    WorkersSimpleTable,
    Codes,
    CreateCode,
    CreateWorker,
    Incidences,
    Admins,
    WorkcenterSelectorNav,
    AddAdmin,
  },
  data() {
    return {
      tabItems: [
        'Incidencias', 'Personal', 'Codigos SAP',
      ],
      tab: null,
      // workcenter data
      totalWorkers: 0,
      totalIncidences: 0,
      totalUnsolved: 0,
      totalSolved: 0,
      workcenterId: null,
      isAdmin: false,
      items: {},
      checkboxNonResolved: true,
      checkboxResolved: false,
      adminEditIsHidden: true,

    };
  },
  mounted() {
    if (this.$store.state.projectId === null) {
      this.$router.push({ name: 'SelectWorkCenter' });
    } else {
      this.workcenterId = this.$store.state.projectId;
      // if user has role SUPER_ADIMN or ADMIN, get users
      if (this.$store.getters['auth/hasRole'](['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])) {
        this.isAdmin = true;
        this.getWorkcenters();
      }
    }
  },
  methods: {
    goToPopulationPanel(id) {
      this.$router.push(`/population/${id}`);
    },
    goToQuadrants() {
      this.$router.push({ name: 'AdminCalendar' });
    },
    goToN2() {
      this.$router.push({ name: 'GenerateN2' });
    },
    updateTotal(total) {
      this.totalWorkers = total;
    },
    updateTotalIncidences(total) {
      this.totalIncidences = total;

      scheduleService
        .countUnsolvedIncidencesByWorkCenter(this.workcenterId)
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          this.totalUnsolved = response;
        });

      scheduleService
        .countSolvedIncidencesByWorkCenter(this.workcenterId)
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          this.totalSolved = response;
        });
    },
    goToWorkers() {
      this.$router.push({ name: 'Workers' });
    },
    getWorkcenters() {
      workcenterService.getAllWorkcenters().then((response) => {
        this.sourceOptions = response.content.map((ele) => ({
          value: ele.id,
          text: ele.name,
          description: ele.description,
          typeId: ele.workcenterType,
          creationTime: new Date(ele.creationTime).toLocaleDateString(),
          icon: 'business_center',
          color: '#424242',
        }));
        this.items = this.sourceOptions;
      });
    },
    updateShowIncidences() {
      this.$refs.incidences.updateShow(this.checkboxNonResolved, this.checkboxResolved);
    },
    updateAdminsTable() {
      this.$refs.adminsTable.updateTable();
    },
    updateAddAdmin() {
      this.$refs.addAdmin.getUsers();
    },
  },
};
</script>
<style lang="scss" scoped>
.view-ct-dashboard {
  padding: calc(var(--base) * 3) calc(var(--base) * 2);
  grid-column: 1 / span 6;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: calc(var(--base) * 2);
  padding-top: calc(var(--base) * 3) !important;

  @media (min-width: 1024px) {
    grid-column: 1 / span 12;
    padding: calc(var(--base) * 3) calc(var(--base) * 3);
    padding-bottom: calc(var(--base) * 4);
    height: 100%;
  }

  .dashboard-panels {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .dashboard-panels--tabs {
    margin-bottom: calc(var(--base) * 1);
    min-height: 50px;
  }

  .dashboard-panels--tabs-panel {
    height: 100%;
  }

  .v-window.v-item-group.theme--light.v-tabs-items {
    height: 100%;
  }
}
</style>
<style lang="scss">
.view-ct-dashboard {
  & .v-input.v-input--hide-details.theme--light.v-input--selection-controls.v-input--checkbox {
    margin-top: 0;
    padding-top: 0;

    & .v-label.theme--light {
      margin: 0;
    }
  }
}
</style>
