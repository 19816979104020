<template>
  <div class="grid-container">
    <CreateWorker class="mb-3" :workcenterId="this.$route.params.id" />
    <CreateCode class="mb-3" :workcenterId="this.$route.params.id" />
    <div class="left-side">
      <!-- Add loading bar -->
      <v-progress-linear v-if="loading" :color="$getColor('vuetifyColor1')" :indeterminate="true"
        class="loading-bar"></v-progress-linear>
      <!-- Start project information v-card -->

      <v-card :elevation="$getCardOptions('elevation')" :loading="loading"
        class="card dashboard_box" v-if="!loading && workcenter">
        <div class="card-thumbnail">
          <v-avatar :color="$getColor('vuetifyColor1')" size="62">
            <v-icon dark>
              business_center
            </v-icon>
          </v-avatar>
        </div>
        <v-card-text class="card-label">
          <div>Nombre:</div>
        </v-card-text>
        <v-card-title class="card-title">{{ workcenter.name }}</v-card-title>
        <v-card-text class="card-label mt-3">
          <div>Descripción:</div>
        </v-card-text>
        <div class="card-role-wrapper">
          <div class="role-title">{{ workcenter.description }}</div>
        </div>
        <v-card-text class="card-label mt-3">
          <div>Fecha de creación:</div>
        </v-card-text>
        <div class="card-role-wrapper">
          <div class="role-title">{{ new Date(workcenter.creationTime).toLocaleString() }}</div>
        </div>
        <v-divider class="mx-4 card-divider"></v-divider>
        <v-card-actions class="card-actions">
          <v-btn @click="openWorkers()"
          :color="$getColor('vuetifyColor1')" text>
            <v-icon class="mr-2">launch</v-icon>
            Ver personal
          </v-btn>
          <v-btn @click="openCalendar()"
          :color="$getColor('vuetifyColor1')" text>
            <v-icon class="mr-2">event</v-icon>
            Calendario
          </v-btn>
        </v-card-actions>
      </v-card>
      <!-- End project information v-card -->
    </div>
    <div class="right-side">
      <!-- Start v-tabs -->
      <v-tabs v-model="tab" background-color="transparent" :color="$getColor('vuetifyColor1')"
        class="dashboard_box dashboard-tabs" grow icons-and-text>
        <v-tab>Personal
          <v-icon>people</v-icon>
        </v-tab>
        <v-tab>Códigos SAP
          <v-icon>discount</v-icon>
        </v-tab>
      </v-tabs>
      <!-- Add loading bar -->
      <v-progress-linear v-if="loading" :color="$getColor('vuetifyColor1')" :indeterminate="true"
        class="loading-bar"></v-progress-linear>
      <v-tabs-items v-model="tab" class="p-1" v-if="!loading">
        <v-tab-item>
          <WorkersSimpleTable/>
        </v-tab-item>
        <v-tab-item>
          <Codes :workcenterId="this.$route.params.id" />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import workcenterService from '@/services/workcenter.service';
import CreateWorker from '@/views/_FATDA/components/CreateWorker.vue';
import Codes from '@/views/_FATDA/components/Codes.vue';
import CreateCode from '@/views/_FATDA/components/CreateCode.vue';
import WorkersSimpleTable from '@/views/_FATDA/components/WorkersSimpleTable.vue';

export default {
  name: 'workcenter-info',
  data() {
    return {
      userId: null,
      userRole: null,
      userName: null,
      user: [],
      info: null,
      loading: false,
      errored: false,
      // new data
      tab: null,
      loaded: false,
      workcenter: null,
    };
  },
  components: {
    CreateWorker,
    Codes,
    CreateCode,
    WorkersSimpleTable,
  },
  created() {
    if (this.$store.state.project === null) {
      this.$router.push({ name: 'SelectWorkCenter' });
    }
  },
  mounted() {
    this.getWorkCenterInfo();
  },
  computed: {
  },
  methods: {
    getWorkCenterInfo() {
      this.loading = true;
      workcenterService.getWorkcenter(this.$route.params.id)
        .then((response) => {
          this.workcenter = response;
          this.loading = false;
        })
        .catch((error) => {
          this.errored = true;
          this.loading = false;
          console.log(error);
        });
    },
    openWorkers() {
      this.$router.push({ name: 'Workers' });
    },
    openCalendar() {
      this.$router.push({ name: 'AdminCalendar' });
    },
  },
};
</script>

<style scoped>
.item {
  margin-bottom: 2rem;
}

.card-filter-button {
  padding: 0 10px;
  display: flex;
  background-color: #fff;
  border: none;
}

.card-filter-button:hover {
  background-color: #fff;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #fff;
  border-color: #545b62;
  box-shadow: 0 0 0 0.1rem rgb(130 138 145 / 50%);
}
</style>
