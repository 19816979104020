<template>
  <div class="page page-validation">
    <v-dialog v-model="dialog" class="dialog-wrapper" width="650px">
      <template v-slot:activator="{ on, attrs }">
        <div class="pb-4">
          <!-- Span with click to v-on=on -->
          <v-icon left>lock_reset</v-icon>
          <a v-bind="attrs" v-on="on" class="text-decoration-underline">
            Recuperar contraseña
          </a>
        </div>
      </template>
      <v-card outlined class="p-3" :elevation="$getCardOptions('elevation')">
            <v-card-title>
              <section-header
              first="Introduce tu email"
              second="y te llegará un enlace para recuperar tu contraseña"
              />
            </v-card-title>
            <v-card-text>
              <v-form ref="form">
                <label class="input-field--label"> Correo electrónico: </label>
                <v-text-field v-model="email" placeholder="Email"
                  :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="local_offer">
                </v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="white--text" :rounded="$getPrimaryButton('rounded')"
                :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')"
                large @click="handleEmailRecovery">
                <v-icon left>mdi-key</v-icon>
                Recuperar contraseña
              </v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>
  </div>
</template>
<script>
import SectionHeader from '@/components/Headers/SectionHeader.vue';

export default {
  name: 'RecoverPassword',

  methods: {
    handleEmailRecovery() {
      this.sending = true;
      this.loading = true;
      this.$store
        .dispatch('auth/recoverPassword', this.email)
        .then(

          () => {
            this.loading = false;
            this.message = 'Email with recovery link was sent';
            this.sending = false;
            this.dialog = false;
          },
          (error) => {
            this.loading = false;
            this.message = (error.response
              && error.response.data
              && error.response.data.message)
              || error.message
              || error.toString();
            this.sending = false;
          },
        );
    },
  },
  data() {
    return {
      email: null,
      loading: false,
      sending: false,
      message: '',
      dialog: false,
    };
  },
  components: {
    SectionHeader,
  },

}; </script>
<style lang="scss" scoped>
.form-nav-buttons {
  width: 100%;
  max-width: calc(var(--base) * 20.875);
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: calc(var(--base) * 0.75);
  font-size: calc(var(--base) * 2.5);
  position: absolute;
  bottom: calc(var(--app-height) * 0.08095952024);
  left: 50%;
  transform: translateX(-50%);
  cursor: default;
  @media (min-width: 1024px) {
    bottom: calc(var(--app-height) * 0.2474541752);
  }
  button {
    text-transform: lowercase;
    letter-spacing: 2.5%;

    cursor: pointer;
    &:hover {
      text-decoration: underline;
      font-weight: 600;
    }
  }
}
.form-step--container {
  position: relative;
  min-height: var(--app-height);
  max-height: var(--app-height);
  height: 100%;
}
.form-step--title {
  position: absolute;
  top: calc(var(--app-height) * 0.1709145427);
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  font-size: calc(var(--base) * 3);
  font-weight: 700;
  text-align: center;
  padding: 0 calc(var(--base) * 2);
  // margin-bottom: calc(var(--app-height) * 0.06446776612);
  @media (min-width: 1024px) {
    top: calc(var(--app-height) * 0.2657841141);
    font-size: calc(var(--base) * 4);
    // margin-bottom: calc(var(--app-height) * 0.08350305499);
  }
}
.form-step--wrapper {
  height: 100%;
  min-height: calc(var(--app-height) * 0.5247376312);
  max-height: calc(var(--app-height) * 0.5247376312);
  width: 100%;
  position: absolute;
  top: calc(var(--app-height) * 0.3073463268);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
    justify-content: center;
  @media (min-width: 1024px) {
    min-height: calc(var(--app-height) * 0.2871690428);
    max-height: calc(var(--app-height) * 0.2871690428);
    top: calc(var(--app-height) * 0.3849287169);
  }
  .form--content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 0;
    row-gap: calc(var(--base) * 2);
    .v-input {
      width: 100%;
      max-width: 86.93333333vw;
    }
    @media (min-width: 1024px) {
      .v-input {
        max-width: 39.48412698vw;
      }
    }
  }
}
</style>
