<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="admin--worker-calendar">
    <v-scroll-y-transition mode="out-in">
      <div class="calendar">
        <div class="calendar-navigation">
          <h3
            v-if="$refs.calendar"
            class="calendar-selector red--text text--accent-2"
          >
            {{ $refs.calendar.title }}
          </h3>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-progress-circular
            v-if="loading"
            :color="$getColor('vuetifyColor1')"
            class="linear-progress"
            indeterminate
          >
          </v-progress-circular>
        </div>
        <v-calendar
          v-show="!isDetailView"
          ref="calendar"
          v-model="focus"
          :color="$getColor('vuetifyColor3')"
          :events="events"
          :event-color="getEventColor"
          :type="type"
          locale="es"
          :weekdays="weekdays"
          @click:event="showEvent"
          @change="updateRange"
          :interval-count="0"
          disabled
        >
          <template  v-slot:event="{ event }">
            <div class="event-chip" :class="event.dayBlocked ? 'blocked' : ''">
              <span style="font-weight: 600">{{ event.type }}</span>
              <span
                style="font-weight: 400"
                class="pl-1"
                v-if="event.type && event.extraHours.length > 0"
              >
                Uds. {{ event.extraHours }}
              </span>
            </div>
          </template>
          </v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card
            color="grey lighten-4"
            min-width="390px"
            max-width="720px"
            flat
          >
            <v-toolbar
              :color="selectedEvent.color"
              :dark="false"
              :elevation="0"
            >
              <v-toolbar-title>
                {{ selectedEvent.name }}
                </v-toolbar-title
              >
              <v-toolbar-title>
                {{ selectedEvent.type }}
                </v-toolbar-title
              >
              <v-icon style="margin-left: 0.5rem">{{
                selectedEvent.icon
              }}</v-icon>
              <v-spacer></v-spacer>
              <v-btn icon @click="selectedOpen = false">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <div class="card-field-group">
                <div class="card-field two-col">
                  <div class="card-field__label">Fecha:</div>
                  <div class="card-field__value">
                    <v-icon>mdi-calendar</v-icon>
                    {{ formatDateWithTime(selectedEvent.start) }}
                  </div>
                </div>
                <div class="card-field two-col">
                  <div class="card-field__label">Tipo:</div>
                  <div class="card-field__value">
                    <v-icon>{{ selectedEvent.icon }}</v-icon>
                    {{ selectedEvent.type }}
                  </div>
                </div>
              </div>
              <v-divider></v-divider>
              <div class="card-field-group" v-if="selectedEvent.codes">
                <div class="card-field">
                  <div class="card-field__label">Códigos:</div>
                  <div class="card-field__value codes-list">
                    <selection-card
                      v-for="code in selectedEvent.codes"
                      :key="code.id"
                      :name="code.name"
                      selected
                      size="large"
                      :description="code.code"
                      disableMouse
                      :class="
                        code.autoVariable
                          ? 'swiper-slide customGreen'
                          : 'swiper-slide customRed'
                      "
                    />
                  </div>
                </div>
              </div>
            </v-card-text>
            <v-card-actions class="justify-center">
              <!--
                <v-btn
                depressed
                :color="selectedEvent.color"
                block
                @click="goToWorkerPanel()"
                x-large
              >
                Ver cuadrante
              </v-btn>
               -->
            </v-card-actions>
          </v-card>
        </v-menu>
        <div v-show="isDetailView">
          <WorkerSchedule
            ref="workerSchedule"
            @backToCalendar="backToCalendar()"
          />
        </div>
      </div>
    </v-scroll-y-transition>
  </div>
</template>

<script>
import scheduleService from '@/services/schedule.service';
import SelectionCard from '@/components/Cards/CodeCard.vue';
import WorkerSchedule from '@/views/_FATDA/components/WorkerSchedule.vue';

export default {
  name: 'worker-calendar',
  components: {
    SelectionCard,
    WorkerSchedule,
  },
  props: {
    workerId: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Mes',
        week: 'Semana',
        day: 'Dia',
      },
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      items: null,
      loading: false,
      loaded: true,

      n2current: [],
      users: [],
      active: [],
      open: [],

      activeId: null,
      minDate: null,
      maxDate: null,

      isDetailView: false,

      dayBlocked: true,
    };
  },

  methods: {
    updateData() {
      this.getUserSchedule(this.workerId);
    },
    getUserSchedule(id) {
      this.loading = true;
      const dateFrom = new Date(this.minDate.date).toISOString().split('T')[0];
      const dateTo = new Date(this.maxDate.date).toISOString().split('T')[0];
      scheduleService.getDaysInRange(id, dateFrom, dateTo).then((response) => {
        this.n2current = response;
        this.loading = false;
        this.updateRangeN2();
      });
    },
    getDayStyle(date) {
      const event = this.events.find((e) => e.start === date);
      if (event) {
        return {
          backgroundColor: event.color,
        };
      }
      return {};
    },
    formatDateWithTime(date) {
      const d = new Date(date);
      const month = `0${d.getMonth() + 1}`.slice(-2);
      const day = `0${d.getDate()}`.slice(-2);
      return `${day}/${month}/${d.getFullYear()}`;
    },
    goToWorkerPanel() {
      this.$router.push({
        name: 'WorkerPanel',
        params: { id: this.active[0] },
      });
    },

    emitSelection() {
      this.$emit('focus', this.focus);
    },

    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = '';
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      this.focus = event.date;
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        // eslint-disable-next-line no-return-assign
        requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)));
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRangeN2() {
      if (this.loaded) {
        const events = [];
        // items foreach
        this.n2current.forEach((item) => {
          events.push({
            start: new Date(item.date),
            end: new Date(item.date),
            date: item.date,
            type: item.type
              ? this.$getAppointmentTypeValues(item.type).label
              : 'Sin completar',
            longType: item.type
              ? this.$getAppointmentTypeValues(item.type).longLabel
              : 'Sin completar',
            color: item.type
              ? this.$getAppointmentTypeValues(item.type).color
              : 'white',
            icon: item.type
              ? this.$getAppointmentTypeValues(item.type).icon
              : 'mdi-calendar',
            codes: item.codes,
            participants: null,
            timed: false,
            extraHours: item.extraHours,
            dayBlocked: item.blocked,
            blocked: item.blocked,
          });
        });
        this.events = events;
        this.$refs.workerSchedule.updateSchedule(events);
      }
    },
    updateRange(obj) {
      this.minDate = obj.start;
      this.maxDate = obj.end;
      // this.updateRangeN2();
      this.getUserSchedule(this.workerId);
    },

    updateIsDetailView(val) {
      this.isDetailView = val;
    },
  },
  watch: {
    focus() {
      this.emitSelection();
    },
  },
};
</script>
<style lang="scss" scoped>
.admin--worker-calendar {
  width: 100%;

  .calendar {
    width: 100%;

    .calendar-selector {
      text-transform: capitalize;
    }

    .calendar-navigation {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: calc(var(--base) * 1);
      margin-bottom: calc(var(--base) * 1);

      h3 {
        font-size: calc(var(--base) * 2.3);
        font-weight: 400;
      }
    }

    .event-chip {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: rgb(var(--bodyColor));
      font-weight: 500;
      &.blocked {
        &:before {
          content: "🔒";
        }
      }
    }
  }
}

.two-columns-items {
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--base) * 4);
}

.card-field-group {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  gap: calc(var(--base) * 2);

  .card-field {
    display: flex;
    flex-direction: column;
    gap: calc(var(--base) * 0.5);
    width: 100%;

    &__value {
      width: 100%;
      min-height: calc(var(--base) * 5);
      background-color: rgb(var(--bodyBg));
      padding: calc(var(--base) * 1) calc(var(--base) * 2);
      padding-left: calc(var(--base) * 1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: calc(var(--base) * 1);
      border-radius: calc(var(--base) * 0.5);
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */

      ::-webkit-scrollbar {
        display: none;
      }

      &.codes-list {
        overflow-x: scroll;
        background-color: transparent;
        margin-right: calc(var(--base) * 2);
        padding: 0;
        justify-content: flex-start;
      }
    }
  }
}
</style>
<style lang="scss">
.view--admin-calendar .v-treeview-node__level {
  display: none !important;
}

.v-subheader.theme--light {
  padding: 0 !important;
  height: calc(var(--base) * 3) !important;
  margin-bottom: calc(var(--base) * 0.5) !important;
}

.view--admin-calendar .v-toolbar__content {
  padding: 0 !important;
  height: auto !important;
}

.view--admin-calendar .v-calendar-weekly__head-weekday {
  background: white !important;
  border: 1px solid rgba(black, 0.1) !important;
  border-top: none !important;

  &:first-of-type {
    border-left: none !important;
  }
}

.view--admin-calendar .v-divider {
  margin-bottom: calc(var(--base) * 0.5) !important;
  margin-top: calc(var(--base) * 0.5) !important;
}

.view--configure-time-admin {
  & .v-picker__body.theme--light {
    margin: 0;
    width: 100% !important;
    padding: calc(var(--base) * 1) calc(var(--base) * 2);
  }

  & .v-date-picker-table .v-btn {
    font-size: calc(var(--base) * 2);
  }

  & .v-picker.v-card.v-picker--date.theme--light {
    height: 100%;

    & table {
      height: 100%;
    }
  }

  & .v-picker__body.theme--light {
    & > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  & .v-date-picker-table.v-date-picker-table--date.theme--light {
    height: 100%;
  }

  & .accent--text {
    button {
      font-size: calc(var(--base) * 2.5);
      text-transform: capitalize;
    }
  }

  & .v-date-picker-header.theme--light {
    margin-top: calc(var(--base) * 1);
    margin-bottom: calc(var(--base) * 2);
  }

  & .v-date-picker-title {
    color: #161616;
    text-transform: capitalize;
  }

  & .v-btn.v-btn--active.v-btn--rounded.theme--light.accent {
    background-color: #f4796b !important;

    & .v-btn__content {
      color: #161616;
    }
  }
}
</style>
<style lang="scss" scoped>
  ::v-deep .v-calendar .v-event{
    margin-left: 2px;
  }
</style>
