<template>
  <div>
    <canvas id="role-chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'Role-Chart',

  props: {
    colors: {
      type: Array,
      default: () => ['#4caf50', '#ff9800', '#ff5722', '#9c27b0', '#2196f3', '#009688'],
    },
  },

  data() {
    return {
      chartObj: null,
      roleData: {
        totalUser: 0,
        totalSuperAdmin: 0,
      },
      roleChartData: {
        type: 'doughnut',
        data: {
          labels: ['Trabajador', 'Encargado', 'Administrador', 'Super Administrador'],
          datasets: [
            {
              label: 'Total Users',
              data: [0, 0],
              backgroundColor: this.colors,
              borderColor: '#fff',
              borderWidth: 0,
            },

          ],
        },
        options: {
          layout: {
            padding: {
              left: 30,
              right: 30,
              top: 10,
              bottom: 10,
            },
          },
          plugins: {
            legend: {
              position: 'bottom',
            },
          },
          responsive: true,
          autoPadding: false,
          lineTension: 1,
          scales: {

          },
        },
      },
    };
  },

  mounted() {
    const ctx = document.getElementById('role-chart');
    this.roleChartData.data.datasets[0].data = [
      this.roleData.totalUser,
      this.roleData.totalResponsable,
      this.roleData.totalAdmin,
      this.roleData.totalSuperAdmin,
    ];
    // eslint-disable-next-line no-new
    this.charObj = new Chart(ctx, this.roleChartData);
  },

  methods: {
    updateRoleData(val) {
      this.roleData.totalUser = val.totalUser;
      this.roleData.totalResponsable = val.totalResponsable;
      this.roleData.totalAdmin = val.totalAdmin;
      this.roleData.totalSuperAdmin = val.totalSuperAdmin;

      this.roleChartData.data.datasets[0].data = [
        this.roleData.totalUser,
        this.roleData.totalResponsable,
        this.roleData.totalAdmin,
        this.roleData.totalSuperAdmin,
      ];
      this.charObj.update();
    },
  },
};
</script>
