<template>
  <div>
    <div class="loader-overlay" v-if="loading">
      <v-progress-linear
        :color="$getColor('vuetifyColor1')"
        class="linear-progress"
        indeterminate
      >
      </v-progress-linear>
    </div>
    <div>
      <v-data-table
        :headers="headers"
        :items="tableData"
        :options.sync="options"
        :server-items-length="count"
        class="elevation-0"
        calculate-widths
        :color="$getColor('vuetifyColor1')"
        outlined
        flat
        elevation="0"
        :footer-props="{
        showFirstLastPage: true,
        'items-per-page-text': 'Elementos por página',

      }"
      >
        <template v-slot:[`item.userPanel`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                class="mr-4"
                :dark="$getPrimaryButton('dark')"
                depressed
                :rounded="$getPrimaryButton('rounded')"
                :outlined="$getPrimaryButton('outlined')"
                :color="$getPrimaryButton('color')"
                @click="goToView(item.id)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-account-cog</v-icon>
              </v-btn>
            </template>
            <span>Panel de empleado</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                depressed
                small
                :dark="$getPrimaryButton('dark')"
                :rounded="$getPrimaryButton('rounded')"
                :outlined="$getPrimaryButton('outlined')"
                :color="$getPrimaryButton('color')"
                @click="goToEditSchedule(item.id)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>edit_calendar</v-icon>
              </v-btn>
            </template>
            <span>Editar cuadrantes</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <v-chip
            :color="$getAppointmentTypeValues(item.appointment.type).color"
            :dark="$getAppointmentTypeValues(item.appointment.type).dark"
          >
            Empleado
          </v-chip>
        </template>
        <template v-slot:[`item.idDocument`]="{ item }">
          <span class="caption font-italic">{{ item.idDocument }}</span>
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <span class="font-weight-medium">{{ item.email }}</span>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <span>{{ item.name }} {{ item.lastName1 }}</span>
        </template>
        <template v-slot:[`item.totalSAP`]="{ item }">
          <v-avatar
            color="primary"
            size="35"
          ><span class="white--text">{{ item.n2Codes.length }}</span></v-avatar>
        </template>
        <template v-slot:[`item.creationTime`]="{ item }">
          <span>
            {{ new Date(item.creationTime).toISOString().split("T")[0] }}
          </span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import axios from '@/services/http-common';
import authHeader from '@/services/auth-header';
import authService from '@/services/auth.service';

export default {
  name: 'workers-simple-table',
  props: {},
  data() {
    return {
      authService,
      isAdmin: false,
      filter: {
        idDocument: null,
        name: null,
        namePartners: null,
        email: null,
        type: null,
        phone: null,
        date: null,
      },

      tableData: [],
      count: 0,

      loading: true,
      cancelTokenSource: null,

      options: {},
      headers: [

        { text: 'DNI', value: 'idDocument' },
        { text: 'Nombre y apellidos', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Códigos SAP', value: 'totalSAP' },
        { text: 'Fecha de creación', value: 'creationTime' },
        {
          text: 'Acciones',
          sortable: false,
          value: 'userPanel',
        },

      ],
    };
  },
  created() {
    if (
      this.authService.getDecodedJwtToken(this.$store.state.auth.user).rol
      === 'ROLE_SUPER_ADMIN'
    ) {
      this.isAdmin = true;
    }
  },
  watch: {
    options: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },
  components: {
  },
  methods: {
    getRequestParams() {
      const params = {};
      params.workCenter = this.$store.state.projectId;

      // eslint-disable-next-line prefer-destructuring
      params.sort = this.options.sortBy[0];
      if (this.options.sortDesc[0]) {
        params.direction = 'desc';
      } else {
        params.direction = 'asc';
      }

      params.page = this.options.page - 1;
      params.size = this.options.itemsPerPage;
      return params;
    },

    goToView(id) {
      return this.$router.push({ name: 'WorkerPanel', params: { id } });
    },
    goToEditSchedule(id) {
      return this.$router.push({ name: 'ConfigureTimeAdmin', params: { id } });
    },

    getItems() {
      if (this.loading && this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }
      this.loading = true;
      this.cancelTokenSource = axios.CancelToken.source();
      axios
        .get(`${process.env.VUE_APP_API_URL}worker`, {
          headers: authHeader(),
          cancelToken: this.cancelTokenSource.token,
          params: this.getRequestParams(),
        })
        .then((response) => {
          this.tableData = response.data.content;
          this.count = response.data.totalElements;
          this.updateTotal();
          this.loading = false;
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            this.errored = true;
            this.loading = false;
          }
        });
    },

    updateTotal() {
      this.$emit('updateTotal', this.count);
    },
  },
};
</script>
<style>
.centered-content-column div {
  display: flex;
  justify-content: center;
}
</style>
<style scoped>
.table_box {
  min-height: 150px;
  position: relative;
  width: 100%;
}
</style>
<style lang="scss" scoped>
.pagination-container {
  display: flex;
  flex-direction: column;
}

.pagination-options {
  margin-top: 1em;
  margin-bottom: 1em;
}

.pagination-wrapper {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;

  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    width: max-content;
  }
}

.loader-overlay {
  position: relative;
}
</style>
