<template>
  <div>
    <mdtec-panel>
      <template v-slot:content>
        <!-- Start list of dates with the details of the event -->
        <v-list>
          <v-list-item-group>
            <v-list-item v-for="item in orderedSchedule" :key="item.id">
              <v-list-item-content>
                <v-list-item-title class="text-subtitle-2">
                  {{ item.date }}
                  <span>({{ getStringDate(item.date) }})</span>
                  <v-chip :color="item.color" text-color="black" label class="font-weight-bold ml-3">
                    {{ item.longType }}
                  </v-chip>
                  <!-- start v-chip for extraHours in grey color -->
                  <v-chip v-if="item.extraHours.length > 0" color="grey" text-color="black" label
                    class="ml-3">
                    Unidades: {{ item.extraHours }}
                  </v-chip>
                  <span v-if="item.blocked" class="blocked--day">🔒</span>
                  <v-btn class="ml-2" small depressed :color="item.blocked ? 'grey' : 'primary'" dark @click="changeDayBlockedStatus(item.id)">
                    <span v-if="!item.blocked"><v-icon left dark small>mdi-lock</v-icon>Bloquear
                    </span>
                    <span v-else>Desbloquear
                    </span>
                </v-btn>
                </v-list-item-title>
                <v-list-item-title class="pb-2">

                </v-list-item-title>
                <!-- for each code in item.codes, show the code.code and code.name -->
                <div v-for="code in item.codes" :key="code.id">
                  <v-chip :color="code.autoVariable ? 'grey' : '#f4796b'" text-color="black" label
                    class="font-weight-bold ml-3" x-small>
                    {{ code.code }}
                  </v-chip>
                  <span class="ml-2 text-body-2">{{ code.name }}</span>
                </div>
                <v-list-item-subtitle>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
    </mdtec-panel>
  </div>
</template>

<script>
import authService from '@/services/auth.service';
import scheduleService from '@/services/schedule.service';
import MdtecPanel from '@/components/Cards/MdtecPanel.vue';

export default {
  props: {
  },
  components: {
    MdtecPanel,
  },
  data() {
    return {
      authService,
      // new data
      isAdmin: false,
      schedule: [],
      loading: false,
    };
  },
  computed: {
    // new computed property with schedule array but ordered by date
    orderedSchedule() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.schedule.sort((a, b) => a.date.localeCompare(b.date));
    },

  },

  methods: {

    updateSchedule(val) {
      this.schedule = val;
    },
    getStringDate(date) {
      const options = {
        weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
      };
      return new Date(date).toLocaleDateString('es-ES', options);
    },
    // function to go back to calendar
    backToCalendar() {
      this.$emit('backToCalendar');
    },
    changeDayBlockedStatus(id) {
      this.loading = true;
      scheduleService
        .changeDayBlockedStatus(id)
        .then(() => {
          this.loading = false;
          this.$emit('refreshWorkerScheduleData', true);
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.isAdmin = this.$store.getters['auth/hasRole'](['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']);
  },
};
</script>
<style lang="scss" scoped>

.blocked--day{
  font-size: 25px;
  padding-left: 5px;
}
.view--worker-panel {
  height: 100%;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .grid-container {
    display: grid;
    gap: calc(var(--base) * 2);
    grid-template-columns: repeat(12, 1fr);
    height: 100%;

    .left-side {
      grid-column: 1 / span 5;
    }

    .right-side {
      grid-column: 6 / span 7;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .card {
      padding: 0;
      padding-top: 2em;
    }

    .full-height {
      height: 100%;
    }
  }
}

.button-edit {
  margin-right: 1em;
  margin-left: 1em;
  display: flex;
  justify-content: flex-end;
}

.worker-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  padding: calc(var(--base) * 3) 0;
}

.worker-info {
  padding-top: calc(var(--base) * 2);
}

.dashboard_box {
  border: solid 1px #e5e5e5;
}

.card-thumbnail {
  padding: 1em;
  width: 100%;
  // min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;

  .card-avatar {
    margin: 0;
  }
}

.card-label.v-card__text {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0.5em;
}

.v-card__title.card-title {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 1em;
}

// .projects-table {
//   padding-left: 1em;
//   padding-right: 1em;
// }

.card-divider {
  margin-bottom: 2em;
}

.card-actions.v-card__actions {
  padding: 1em;
  margin-top: 0;
}

.dashboard_box.chart-box {
  margin-bottom: 1em;
}

.panel-tab {
  margin-bottom: 1em;
}

.chart-box {
  padding: 1em;
}

.card-role-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;

  .role-title {
    font-size: 1.25rem;
  }
}

.add-project-dialog {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.edit-user-dialog {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 1em;
}

.rol-select {
  margin-right: 1em;
}
</style>
<style lang="scss">
.view--worker-panel {
  & .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgba(var(--bodyColor), 0.1) !important;
    color: rgba(var(--bodyColor), 0.6) !important;
  }

  & .theme--dark.v-btn.v-btn--disabled,
  .theme--dark.v-btn.v-btn--disabled .v-btn__loading,
  .theme--dark.v-btn.v-btn--disabled .v-icon {
    color: rgba(var(--bodyColor), 0.6) !important;
  }

  & .mdtec-card.worker-panel {
    height: 100%;
  }

  & .mdtec-card.calendar {
    height: 100%;

    & .mdtec-card--content {
      height: 100%;

      & .admin--worker-calendar {
        height: 100%;

        & .calendar {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
      }
    }

    & .theme--light.v-calendar-weekly .v-calendar-weekly__day {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    & .v-event.v-event-start.v-event-end.white--text {
      width: 98% !important;

      & .event-chip {
        color: #161616 !important;
        padding: 0 calc(var(--base) * 0.5);
        font-weight: 400;
        justify-content: flex-start;
      }
    }
  }
}
</style>
