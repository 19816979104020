<template>
    <v-dialog v-model="dialog" class="dialog-wrapper" width="650px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn depressed class="" :dark="$getPrimaryButton('dark')"
          :rounded="$getPrimaryButton('rounded')" :outlined="$getPrimaryButton('outlined')"
          :color="$getPrimaryButton('color')" v-bind="attrs" v-on="on">
          <v-icon left>mdi-account-plus</v-icon>Añadir Trabajador/a
        </v-btn>
      </template>
      <v-container fluid>
            <v-card outlined class="p-3" :elevation="$getCardOptions('elevation')">
              <v-card-title>
                <section-header
                  first="Completa los campos"
                  second="y añade un nuevo empleado"
              />
              </v-card-title>
              <v-card-text>
                <v-form ref="form">
                  <!-- Add v-text-area for description -->
                  <label class="input-field--label"> Nombre: </label>
                  <v-text-field v-model="formData.name" placeholder="Nombre"
                    :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="person">
                  </v-text-field>
                  <label class="input-field--label"> Apellidos: </label>
                  <v-text-field v-model="formData.lastName1" placeholder="Apellidos"
                    :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="person_add">
                  </v-text-field>
                  <!-- Add v-text-area for description -->
                  <label class="input-field--label"> Documento de identificación: </label>
                  <v-text-field v-model="formData.idDocument" placeholder="DNI"
                    :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="badge">
                  </v-text-field>
                  <label class="input-field--label"> Correo electrónico: </label>
                  <v-text-field v-model="formData.email" placeholder="Email"
                    :color="$getColor('vuetifyColor1')" outlined prepend-inner-icon="email">
                  </v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="white--text" :rounded="$getPrimaryButton('rounded')"
                  :outlined="$getPrimaryButton('outlined')" :color="$getPrimaryButton('color')"
                  large @click="newRegister" :disabled="$v.formData.$invalid" :loading="loading">
                  Añadir empleado
                </v-btn>
              </v-card-actions>
            </v-card>
      </v-container>
    </v-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import workcenterService from '@/services/workcenter.service';

import SectionHeader from '@/components/Headers/SectionHeader.vue';

export default {
  name: 'sap-code-register',
  data: () => ({
    mixins: [validationMixin],

    formData: {
      name: null,
      lastName1: null,
      email: null,
      idDocument: null,
    },

    dialog: false,
    loading: false,

  }),
  props: {
    workcenterId: {
      required: true,
    },
  },
  components: {
    SectionHeader,
  },

  validations() {
    const valObj = {
      formData: {
        name: { required },
        lastName1: { required },
        email: { required },
        idDocument: { required },
      },

    };
    return valObj;
  },
  methods: {
    newRegister() {
      this.loading = true;
      workcenterService.createWorker(this.formData, this.workcenterId)
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.dialog = false;
          if (this.$route.name !== 'Workers') {
            this.$router.push({ name: 'Workers' });
          } else {
            // else reload the page
            this.$router.go();
          }
        });
    },
    goToWorkers() {
      this.$router.push({ name: 'Workers' });
    },
  },
};
</script>
