<template>
  <div>

    <!-- Add progress bar -->
    <v-progress-linear v-if="loading" :color="$getColor('vuetifyColor1')" class="linear-progress"
      indeterminate></v-progress-linear>
    <!-- Add content -->
    <v-data-table
    v-if="users && !loading"
      :color="$getColor('vuetifyColor1')"
      :headers="headers" :items="users"
      :items-per-page="10" class="elevation-0">
      <template v-slot:[`item.role`]="{ item }">
          <!-- v-chip for category -->
           <v-chip :color="$getRoleTypeValues(item.role).color" text-color="white">
            {{ $getRoleTypeValues(item.role).label }}
          </v-chip>
      </template>
      <template v-slot:[`item.email`]="{ item }">
        <v-icon v-if="item.email" :color="$getColor('vuetifyColor2')">more_time</v-icon>
      </template>
      <template v-slot:[`item.creationTime`]="{ item }">
          <span class="caption font-italic">{{ item.creationTime }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-show="item.deleteHide" fab color="rgba(0,0,0,0.56)" text small v-bind="attrs" v-on="on"
              @click="item.deleteHide = false"><v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          <span>Borrar Usuario</span>
        </v-tooltip>
        <v-btn fab v-show="!item.deleteHide" color="rgba(0,0,0,0.56)" text small
          @click="unregisterUser(item.username, item.role)"><v-icon color="teal accent-4">mdi-check-circle-outline</v-icon>
        </v-btn>
        <v-btn fab v-show="!item.deleteHide" color="rgba(0,0,0,0.56)" text small @click="item.deleteHide = true"><v-icon
            :color="$getColor('vuetifyColor1')">mdi-close-circle-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import workcenterService from '@/services/workcenter.service';

export default {
  name: 'codes-view',
  props: {
    workcenterId: {
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      loaded: false,
      dataCountLoaded: 0,
      adminAvailable: false,
      items: [],
      count: 0,
      headers: [
        { text: 'Email', value: 'username' },
        { text: 'Rol', value: 'role' },
        { text: 'Fecha de creación', value: 'creationTime' },
        { text: 'Acciones', value: 'actions' },
      ],
      allUsers: [],
      users: [],

    };
  },
  mounted() {
    this.adminAvailable = this.$store.getters['auth/hasRole'](['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']);
    if (!this.adminAvailable) {
      this.$router.push('/acceso-denegado');
    } else {
      this.tabMain = 0;
      this.getUsers();
    }
  },
  methods: {
    getUsers() {
      this.loading = true;
      workcenterService.getUsersByWorkcenter(this.workcenterId).then((response) => {
        this.allUsers = response.content.map((user) => ({
          username: user.username,
          role: user.role,
          creationTime: user.creationTime,
          deleteHide: true,
        }));
        this.count = response.content.length;
        // only admins and encargados
        this.users = this.allUsers.filter((user) => user.role === 'ROLE_ADMIN' || user.role === 'ROLE_ENCARGADO');
        this.loaded = true;
        this.loading = false;
      });
    },
    getRoleOptions(value) {
      const option = this.categoryOptions.find((item) => item.value === value);
      option.color = option.color || 'rgb(255, 64, 129)';
      option.text = option.text || ' - ';
      return option;
    },
    unregisterUser(username, role) {
      this.loading = true;
      if (role === 'ROLE_ENCARGADO') {
        workcenterService.removeManager(username, this.workcenterId).then(() => {
          this.getUsers();
        });
      } else if (role === 'ROLE_ADMIN') {
        workcenterService.removeAdmin(username, this.workcenterId).then(() => {
          this.getUsers();
        });
      }
      this.$emit('update');
    },
    updateTable() {
      this.getUsers();
    },
  },
};
</script>
